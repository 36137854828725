import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './back-icon-link.module.scss';

interface BackIconLinkProps {
    path: string;
}

const BackIconLink = ({ path }: BackIconLinkProps) => {
    return (
        <Link to={path} className={styles.link}>
            <FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" />
        </Link>
    );
};

export default BackIconLink;