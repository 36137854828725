import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './goals.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import HRule from '../../components/HRule/HRule';
import { format, compareDesc } from 'date-fns'
import useGoals from '../../store/goals/hooks/useGoals';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import useSession from '../../custom_hooks/useSession';
import goalsSlice from '../../store/goals/goals-slice';
import GoalsFormField from '../../models/GoalsFormField.enum';
import { goalsStateSelector } from '../../store/goals/goals-selectors';
import { updateGoals } from '../../store/goals/goals-actions';

const Goals  = () => {
    const dispatch = useDispatch();
    const { updateGoalsForm } = goalsSlice.actions;
    const { goalsForm } = useSelector(goalsStateSelector);
    const { goals, isLoading} = useGoals();
    const session = useSession();

    const handleFieldChange = (field: GoalsFormField) => (ev: React.FormEvent<HTMLInputElement>) => {
        dispatch(updateGoalsForm({
            field,
            value: ev.currentTarget.value,
        }));
    };
    
    const handleSaveClick = (ev: React.FormEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        const targetWeight = goalsForm?.get(GoalsFormField.targetWeight);

        if (targetWeight) {
            const updatedGoals = {
                targetWeight: parseInt(targetWeight),
            };
            
            dispatch(updateGoals(session.user, updatedGoals));
        }
    };

    return (<>
    { isLoading && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>Goals</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <form className={classNames("pure-form pure-form-stacked", styles.form)}>
                <fieldset className={classNames(styles.fieldset)}>
                    <div className="pure-g">
                        <div className="pure-u-1 pure-u-md-1-3">
                            <label htmlFor="target-weight">Target Weight (lbs)</label>
                            <input type="text" id="target-weight" className="pure-u-23-24"
                                onChange={handleFieldChange(GoalsFormField.targetWeight)}
                                value={goalsForm?.getWithDefault(GoalsFormField.targetWeight, '')}
                             />
                        </div>
                        <div className={classNames("pure-u-1", styles.buttonContainer)}>
                            <button
                                className="pure-input-1 pure-button pure-button-primary"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </section>
    </div>
    </>);
};

export default Goals;