import firebase from 'firebase';
import Trainer from '../models/Trainer';
import User from '../models/User';
import CallableFunctionName from './CallableFunctionName';
import { getClientColl } from './helpers';

const TrainerInfoAPI = {
    getTrainers: async (): Promise<Trainer[]> => {
        const trainerData = firebase.functions().httpsCallable(CallableFunctionName.getTrainers);
        
        const response = await trainerData();
        
        const trainers = response.data as Trainer[];
        
        return trainers;
    },
};


export default TrainerInfoAPI;
