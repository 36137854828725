import React from 'react'

interface FormErrorProps {
    errorMessage?: string;
}

const FormError = ({ errorMessage }: FormErrorProps) => {
    if (!errorMessage) return null;

    return (
        <span className="pure-form-message-inline form-error">
            {errorMessage}
        </span>
    );
};

export default FormError;
