import { useSelector } from 'react-redux';
import { userIdSelector, userSelector } from '../store/auth/auth-selectors';
import SessionData from '../models/SessionData';

const useSession = function useSession() {
  const userId = useSelector(userIdSelector) || '';
  const user = useSelector(userSelector);
  
  return {
      userId,
      isSignedIn: !!userId,
      user,
  } as SessionData;
}

export default useSession;