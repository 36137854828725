import React, {useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './exercise-programs.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCard from '../../components/ListCard/ListCard';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import ListCardIcon from './components/ListCardIcon/ListCardIcon';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import { Redirect } from 'react-router-dom';
import useUserSelected from '../../store/userData/hooks/useUserSelected';
import useTrainers from '../../store/trainers/hooks/useTrainers';
import Trainer from '../../models/Trainer';
import TrainerExerciseProgram from '../../models/TrainerExerciseProgram';
import useSession from '../../custom_hooks/useSession';
import { updateSelectedExerciseProgram } from '../../store/userData/user-data-actions';

// @TODO api
const headerMessage = 'Programs';

const PLExercisePrograms  = () => {
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSession();
    const {
        userSelected,
        hasLoadedUserSelected,
    } = useUserSelected();
    
    const {
        trainers,
        hasLoaded,
    } = useTrainers();
    
    const isLoading = !(hasLoaded && hasLoadedUserSelected)
    
    if (!isLoading && !(userSelected && userSelected.selectedTrainer)) {
        return <Redirect to="/trainers" />;
    }
    
    const selectedTrainer = hasLoaded && hasLoadedUserSelected
    ? trainers.find((trainer) => trainer.id === userSelected.selectedTrainer) as Trainer
    : null;
    
    const exercisePrograms = hasLoaded && hasLoadedUserSelected
    ? selectedTrainer?.exercisePrograms
    : null;
    
    const hasExerciseProgram = exercisePrograms && !!exercisePrograms.length;

    const selectProgram = (program: TrainerExerciseProgram) => () => {
        setRedirect(true);
        const onComplete = () => {
            setRedirect(true);
        };
        dispatch(updateSelectedExerciseProgram(user, program.exerciseProgramPath, onComplete));
    };
    
    if (redirect) {
        return <Redirect to="/" />;
    }
    
    return (<>
    { isLoading && (<LoadingWithOverlay contained={false} />)}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>{headerMessage}</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            {!(isLoading || hasExerciseProgram) && (<p className="pure-u-1">
                No <strong> Exercise Programs</strong> have been assigned
            </p>)}
            {exercisePrograms && exercisePrograms.map((program) => (
                <div className={classNames('pure-u-1 pure-u-sm-1-3 pure-u-lg-1-4', styles.cardContainer)} key={program.exerciseProgramId}>
                    <ListCard
                        title={program.exerciseProgramName}
                        cardKey={program.exerciseProgramId}
                        description={program.exerciseProgramDescription}
                        RightIcon={<ListCardIcon program={program} selectedProgram={userSelected.selectedExerciseProgram} />}
                        onClick={selectProgram(program)}
                    />
                </div>
            ))}
        </section>
    </div>
    </>);
};

export default PLExercisePrograms;