import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './messages.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import HRule from '../../components/HRule/HRule';
import { format, compareDesc } from 'date-fns'
import useMessages from '../../store/messages/hooks/useMessages';
import useUserSelected from '../../store/userData/hooks/useUserSelected';
import useTrainers from '../../store/trainers/hooks/useTrainers';
import { addMessage } from '../../store/messages/messages-actions';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import useSession from '../../custom_hooks/useSession';

const Messages  = () => {
    const dispatch = useDispatch();
    const [userMessage, setUserMessage] = useState('');
    const {
        messages = [],
        hasSubscribed,
        isLoading: isLoadingMessages,
    } = useMessages();
    const {
        hasLoadedUserSelected,
        userSelected,
    } = useUserSelected();
    const {
        hasLoaded: hasLoadedTrainers,
        trainers,
    } = useTrainers();
    const { user } = useSession();
    
    const messagesEndRef = useRef<HTMLDivElement>(null);
    
    
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);
    
    const displayMessages = messages
    .filter((message) => {
        if (userSelected.selectedTrainer) {
            return message.trainerId === userSelected.selectedTrainer;
        }
        return true;
    })
    .map(event => ({
        ...event,
        messageDate: new Date(event.timestamp),
    })).sort((a, b) => compareDesc(b.messageDate, a.messageDate));
    
    const handleMessageChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
        ev.preventDefault();
        setUserMessage(ev.currentTarget.value);
    }
    
    const handleSendMessage = () => {
        const selectedTrainer = trainers.find((trainer) => trainer.id === userSelected.selectedTrainer);
        
        if (selectedTrainer && userMessage) {
            dispatch(addMessage(user, selectedTrainer.displayName, selectedTrainer.id, userMessage));
            setUserMessage('');
        }
    };
    
    const isLoading = !(hasLoadedUserSelected && hasSubscribed && hasLoadedTrainers) && !isLoadingMessages;

    return (<>
    { isLoading && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>Messages</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <dl className={classNames('pure-u-1', styles.datalist)}>
                {displayMessages.map((message) => (<React.Fragment key={message.messageId}>
                    <dt className={classNames({ [styles.isTrainerMessage]: (!message.isUserMessage)})}>
                        { message.isUserMessage && (
                            <span className={styles.logIcon}>
                                <FontAwesomeIcon icon={['fas', 'circle']} size="sm" />
                            </span>
                        )}
                        {message.message}
                        { !message.isUserMessage && (
                            <span className={styles.userLogIcon}>
                                <FontAwesomeIcon icon={['fas', 'circle']} size="sm" />
                            </span>
                        )}
                    </dt>
                    <dd className={classNames({ [styles.isTrainerMessage]: (!message.isUserMessage)})}>
                        <span className={styles.date}>{format(message.messageDate, 'dd MMM yyyy')} at {format(message.messageDate, 'h:m a')}</span>
                    </dd>
                </React.Fragment>))}
                <div ref={messagesEndRef} />
            </dl>
            <div className={classNames(styles.messageBoxContainer, 'pure-form')}>
                <textarea placeholder="Type your message here"
                    value={userMessage}
                    onChange={handleMessageChange}
                >
                </textarea>
                <div className={classNames(styles.messageButtonContainer)}>
                    <button className="pure-button pure-button-primary" onClick={handleSendMessage}>Send</button>
                </div>
            </div>
        </section>
    </div>
    </>);
};

export default Messages;