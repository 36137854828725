import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './workouts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCard from '../../components/ListCard/ListCard';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import { exerciseProgramsSelector, selectedExercisesSelector } from '../../store/userData/user-data-selectors';
import Exercise from '../../models/Exercise';
import { Redirect, useParams } from 'react-router-dom';

// @TODO api

interface WorkoutsRouteParams {
    splitDay?: string;
}

const Workouts  = () => {
    const [clickedExerciseId, setClickedExerciseId] = useState<null | string>(null);
    let { splitDay } = useParams() as WorkoutsRouteParams;
    
    const exercises = useSelector(selectedExercisesSelector);
    
    const getThumb = (exercise: Exercise) => {
        const thumb = exercise.mediaSources.find((src)=> src.isThumbnail);
        return thumb?.mediaSourceUrl;
    };
    
    const splitDayExercises = splitDay
        ? exercises.filter(exercise => exercise.splitDay === parseInt(splitDay as string))
        : exercises;

    const headerMessage = splitDay
        ? `Day ${splitDay}`
        : 'Workouts';
        
    const handleExerciseClicked = (exerciseId: string) => () => {
        setClickedExerciseId(exerciseId);
    };
        
    if (clickedExerciseId) {
        return <Redirect to={`/details/${clickedExerciseId}`} />;
    }

    return (
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/week" />
            <h1>{headerMessage}</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            {splitDayExercises.map((workout) => (
                <div className={classNames('pure-u-1 pure-u-sm-1-2 pure-u-lg-1-4', styles.cardContainer)} key={workout.exerciseId}>
                    <ListCard
                        title={workout.exerciseName}
                        cardKey={workout.exerciseId}
                        imageSrc={getThumb(workout)}
                        imageAlt={workout.exerciseName}
                        description={workout.description}
                        RightIcon={<FontAwesomeIcon icon={['fas', 'info-circle']} size="lg" />}
                        onClick={handleExerciseClicked(workout.exerciseId)}
                    />
                </div>
            ))}
        </section>
    </div>);
};

export default Workouts;