import React from 'react';
import styles from './list-card.module.scss';
import classNames from 'classnames';

interface ListCardProps {
    cardKey: string | number;
    imageSrc?: string;
    imageAlt?: string;
    title: string;
    description?: string;
    onClick?: (key: string | number) => void; 
    RightIcon?: React.ReactNode;
};

const ListCard = ({
    cardKey,
    imageSrc,
    imageAlt,
    title,
    description,
    onClick,
    RightIcon,
}: ListCardProps) => {
    const handleClick = (ev: React.MouseEvent) => {
        if (onClick) {
            ev.stopPropagation();
            onClick(cardKey);
        }
    }
    
    const maxDescSize = 23;

    return (
    <div className={styles.container} onClick={handleClick}>
        { imageSrc && imageAlt && (
            <div className={styles.imageContainer}>
                <img src={imageSrc} alt={imageAlt} />
            </div>
        )}
        <div className={classNames(styles.textContainer, { [styles.textContainerRow]: !imageSrc})}>
            <header className={styles.header}>
                <span className={styles.headerText}>{title}</span>
            </header>
            {description && (
                <p className={styles.descriptionContainer}>
                    {description.substr(0, maxDescSize)}
                    {description.length> maxDescSize && "..."}
                </p>
            )}
        </div>
        <div className={styles.iconContainer}>
            { RightIcon && RightIcon}
        </div>
    </div>);
};

export default ListCard;