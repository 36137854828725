import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './details.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import HRule from '../../components/HRule/HRule';
import { Link, useParams } from 'react-router-dom';
import { selectedExercisesSelector, isLoadingSelector, lastExercisesForDaySelector, nextExerciseSelector } from '../../store/userData/user-data-selectors';
import { completeExercise } from '../../store/userData/user-data-actions';
import useSession from '../../custom_hooks/useSession';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import Exercise, { ExerciseUnit } from '../../models/Exercise';

import { exerciseStateSelector } from '../../store/exerciseState/exercise-state-selectors';
import exerciseSlice from '../../store/exerciseState/exercise-state-slice';
import MediaSource, { MediaSourceType } from '../../models/MediaSource';

import ReactPlayer from 'react-player';
import { Modal } from 'react-responsive-modal';

interface DetailsRouteParams {
    exerciseId?: string;
}

const Workouts  = () => {
    const { exerciseId } = useParams() as DetailsRouteParams;

    const [exerciseComplete, setExerciseComplete] = useState(false);
    const [selectedVid, setSelectedVid] = useState('');
    const [selectedImage, setSelectedImage] = useState('');

    const { user } = useSession();
    const dispatch = useDispatch();

    const isLoading = useSelector(isLoadingSelector);
    const exercises = useSelector(selectedExercisesSelector);
    const lastExercisesForDay = useSelector(lastExercisesForDaySelector);
    const nextExercise = useSelector(nextExerciseSelector);
    
    const {
        exerciseSetSelected,
        exerciseSetValues,
        exerciseRepsValues,
    } = useSelector(exerciseStateSelector);
    
    const {
        updateExerciseSetSelected,
        updateExerciseSetValue,
        updateExerciseRepValue,
        clearExerciseSetSelected,
        clearExerciseSetValues,
        clearExerciseRepValues,
    } = exerciseSlice.actions;
    
    useEffect(() => {
        setExerciseComplete(false);
    }, [exerciseId])

    if (!exerciseId) {
        return <p>No Selected Exercise</p>;
    }
    
    if (!(exercises && exercises.length)) {
        return <p>No Exercises Exist</p>;
    }
    
    const exercise = exercises.find(exercise => exercise.exerciseId === exerciseId) as Exercise;
    const thumb = exercise?.mediaSources.find(src => src.isThumbnail);
    const otherMedia = exercise?.mediaSources.filter(src => !src.isThumbnail);

    const workoutComplete = lastExercisesForDay.get(exercise.splitDay) === exercise.exerciseId;
    
    const handleSetClicked = (index: number) => () => {
        dispatch(updateExerciseSetSelected({
            exerciseId,
            setKey: index,
            value: !exerciseSetSelected.get(exerciseId)?.get(index),
        }))
    }
    
    const handleComplete = () => {
        if (exercise) {
            dispatch(completeExercise(user, exercise, exerciseSetValues.get(exerciseId), exerciseRepsValues.get(exerciseId), () => {
                setExerciseComplete(true);
                
                dispatch(clearExerciseSetSelected());
                dispatch(clearExerciseSetValues());
                dispatch(clearExerciseRepValues());
            }))
        }
    };
    
    const handleUnitValueChange = (setKey: number) => (ev: React.FormEvent<HTMLInputElement>) => {
        dispatch(updateExerciseSetValue({
            exerciseId,
            setKey,
            value: ev.currentTarget.value,
        }))
    };

    const handleRepValueChange = (setKey: number) => (ev: React.FormEvent<HTMLInputElement>) => {
        dispatch(updateExerciseRepValue({
            exerciseId,
            setKey,
            value: ev.currentTarget.value,
        }))
    };
    
    const handleYoutubeClick = (src: MediaSource) => () => {
        setSelectedVid(src.mediaSourceUrl);
    };
    
    const closeYoutubeModal = () => {
        setSelectedVid('');
    };

    const handleImageClick = (src: MediaSource) => () => {
        if (ReactPlayer.canPlay(src.mediaSourceUrl)) {
            setSelectedVid(src.mediaSourceUrl);
        } else {
            setSelectedImage(src.mediaSourceUrl);
        }
    };
    
    const closeImageModal = () => {
        setSelectedImage('');
    };
    

    return (<>
    <Modal open={!!selectedVid} onClose={closeYoutubeModal} center>
        <div className={styles.modalContent}>
            <ReactPlayer url={selectedVid} height="100%" width="100%" playing={true} controls={true} />
        </div>
    </Modal>
    <Modal open={!!selectedImage} onClose={closeImageModal} center>
        <div className={styles.modalContent}>
            <img src={selectedImage} alt={selectedImage} /> 
        </div>
    </Modal>
    { isLoading && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path={`/workouts/${exercise?.splitDay}`} />
            <h1>Details</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <div className={classNames('pure-u-1 pure-u-sm-1-2 pure-u-lg-1-4', styles.detailsContainer)}>
                <div className={classNames(styles.imageContainer)}>
                    <img src={thumb?.mediaSourceUrl} alt={exercise?.exerciseName} /> 
                </div>
                <div className={classNames(styles.textContainer)}>
                    <header>{exercise?.exerciseName}</header>
                    <p className={classNames(styles.descContainer)}>
                        {exercise?.description}
                    </p>
                    <p className={classNames(styles.descContainer)}>
                        Recommended Reps: {exercise.reps}
                    </p>
                </div>
            </div>
            <div className="pure-u-1">
                <HRule />
            </div>
            {otherMedia && !!otherMedia.length && (<>
                <div className={classNames('pure-u-1 pure-u-sm-1-2 pure-u-lg-1-4', styles.mediaContainer)}>
                    <div className={classNames('pure-g', styles.itemsContainer)}>
                        { otherMedia?.map((src) => (
                            <div className={classNames('pure-u-1-3', styles.imageContainer)} key={src.mediaSourceId}>
                                {
                                    src.mediaSourceType === MediaSourceType.File && (
                                        <button type="button" className={styles.imageButton}
                                            onClick={handleImageClick(src)}
                                        >
                                            { ReactPlayer.canPlay(src.mediaSourceUrl)
                                                ? (<>
                                                    <FontAwesomeIcon icon={["fas", "video"]} size="3x" />
                                                    <span className={styles.sourceName}>{src.mediaSourceName}</span>
                                                  </>)
                                                : (<img src={src.mediaSourceUrl} alt={src.mediaSourceName} /> )
                                            }
                                        </button>
                                    )
                                }
                                {
                                    src.mediaSourceType === MediaSourceType.Youtube && (
                                        <button type="button" className={styles.youtubeButton}
                                            onClick={handleYoutubeClick(src)}
                                        >
                                            <FontAwesomeIcon icon={["fab", "youtube"]} size="3x" />
                                            <span className={styles.sourceName}>{src.mediaSourceName}</span>
                                        </button>
                                    )
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pure-u-1">
                    <HRule />
                </div>
            </>)}
            <div className={classNames('pure-u-1 pure-u-sm-1-2 pure-u-lg-1-4', styles.setsContainer)}>
                <div className={classNames('pure-g', styles.setIconsContainer)}>
                    {/** Exercise In Progress */}
                    {!exerciseComplete && (
                        <header className="pure-u-1">
                            Sets
                        </header>
                    )}
                    {!exerciseComplete  && [...Array(exercise?.sets || 0)].map((_, k) => (<div className="pure-u-1 pure-form" key={k}>
                        <div className="pure-g">
                            <div className={classNames('pure-u-1-8', styles.iconContainer, { [styles.completeSet]: (exerciseSetSelected.get(exerciseId)?.get(k))})}
                                onClick={handleSetClicked(k)}
                            >
                                { exerciseSetSelected.get(exerciseId)?.get(k)
                                    ?(<FontAwesomeIcon icon={['far', 'check-circle']} size="2x" />)
                                    :(<FontAwesomeIcon icon={['far', 'circle']} size="2x" />)
                                }
                            </div>
                            <div className={classNames('pure-u', styles.unitInputContainer)}>
                                <input type="text" value={exerciseRepsValues.get(exerciseId)?.get(k) || ''} onChange={handleRepValueChange(k)} placeholder={exercise.reps.toString()}/>
                                <span className={styles.repsLabel}>reps</span>
                            </div>
                            <div className={classNames('pure-u-3-8', styles.unitInputContainer)}>
                                {
                                    exercise.unit !== ExerciseUnit.none && (<>
                                            <input type="text" value={exerciseSetValues.get(exerciseId)?.get(k) || ''} onChange={handleUnitValueChange(k)} />
                                            <span className={styles.unitLabel}>{exercise.unit || ExerciseUnit.lbs}</span>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>))}
        
                    {!exerciseComplete && (
                        <div className={classNames("pure-u-1", styles.buttonContainer)}>
                            <button
                                className={classNames("pure-button pure-button-primary", styles.completeButton)}
                                onClick={handleComplete}
                            >
                                Complete
                            </button>
                        </div>
                    )}

                    {/** Complete Exercise */}
                    {
                        exerciseComplete && !workoutComplete &&(<>
                        <div className={classNames("pure-u-1", styles.completeMessageContainer)}>
                            <p>You have completed this exercise</p>
                        </div>
                        <div className={classNames("pure-u-1", styles.buttonContainer)}>
                            <Link
                                to={`/details/${nextExercise?.exerciseId}`}
                                className={classNames("pure-button pure-button-primary", styles.nextButton)}
                            >
                                Next Exercise
                            </Link>
                        </div>
                        </>)
                    }

                    {/** Complete Workout */}
                    {
                        exerciseComplete && workoutComplete && (<>
                        <div className={classNames("pure-u-1", styles.completeMessageContainer)}>
                            <p><strong>Congratulations!</strong> You have completed this workout</p>
                        </div>
                        <div className={classNames("pure-u-1", styles.buttonContainer)}>
                            <Link
                                to='/'
                                className={classNames("pure-button pure-button-primary", styles.workoutComplete)}
                            >
                                Done
                            </Link>
                        </div>
                        </>)
                    }
                </div>
            </div>
        </section>
    </div>
    </>);
};

export default Workouts;