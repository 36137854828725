import { createSlice , PayloadAction } from '@reduxjs/toolkit';

interface WeightState {
    isLoading: boolean;
    hasLoadedGoals: boolean;
    requestError: string;
    subscriptionId?: string;
    updatingError: string;
    currentWeight: string;
}

const weightSlice = createSlice({
  name: 'weight',
  initialState: {
    isLoading: false,
    hasSubscribed: false,
    hasLoadedGoals: false,
    requestError: '',
    updatingError: '',
    currentWeight: '',
  } as WeightState,
  reducers: {
    weightUpdateRequested(state) {
      state.isLoading = true;
    },
    weightUpdateCompleted(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.currentWeight = '';
    },
    weightUpdateFailed(state, action:PayloadAction<string>) {
      state.updatingError = action.payload;
      state.isLoading = false;
    },
    updateFormWeight(state, action: PayloadAction<string>) {
      state.currentWeight = action.payload;
    },
    
  }
});

export default weightSlice;
