import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './inactive.module.scss';
import { clientEventsSelector } from '../../store/userData/user-data-selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Inactive  = () => {
    return (
    <div className={styles.container}>
        <section className={styles.topSection}>
            <h1>Inactive</h1>
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <p className="pure-u-1">
                <FontAwesomeIcon icon={["fas", "ban"]} size="5x" />
            </p>
            <p className="pure-u-1">
                Your account has been set to inactive.
            </p>
            <p className="pure-u-1">
                Please contact your trainer.
            </p>
        </section>
    </div>);
};

export default Inactive;