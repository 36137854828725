import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './stats.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import { statsSelector, selectedExercisesSelector } from '../../store/userData/user-data-selectors';
import { getStatRecords } from '../../store/userData/user-data-actions';
import useSession from '../../custom_hooks/useSession';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import { VictoryChart, VictoryLine, VictoryTooltip, VictoryScatter, VictoryVoronoiContainer, } from 'victory';
import StatCalculator, { ChartItem } from '../../models/StatCalculator';
import { StatRecordType } from '../../models/StatRecord';
import HRule from '../../components/HRule/HRule';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';

type DataType = { x: number; y: number; };
type LabelParam = { datum: DataType; }

enum DisplayType {
    Average,
    Max,
}

const Workouts  = () => {
    const { user } = useSession();
    const dispatch = useDispatch();
    const {
        statRecords,
        isLoading,
        hasLoadedStatRecords,
    } = useSelector(statsSelector);
    const [selectedDisplayType, setSelectedDisplayType] = useState(DisplayType.Average);
    const [selectedStatType, setSelectedStatType] = useState(StatRecordType.SETS);
    const [selectedExercise, setSelectedExercise] = useState<null|string>();
    const exercises = useSelector(selectedExercisesSelector);
    
    let data: ChartItem[] = [];
    
    if (hasLoadedStatRecords) {
        const calculator = new StatCalculator(statRecords, selectedStatType);
        
        if (selectedExercise) {
            if (selectedDisplayType === DisplayType.Average) {
                data = calculator.getAverages(selectedExercise) || [];
            } else {
                data = calculator.getMaxes(selectedExercise) || [];
            }
        }
    }
    
    useEffect(() => {
        if (exercises && exercises.length && !selectedExercise) {
            setSelectedExercise(exercises[0].exerciseId);
        }
    }, [exercises]);

    useEffect(() => {
        if (!(isLoading || hasLoadedStatRecords)) {
            dispatch(getStatRecords(user));
        }
    }, []);
    
    const handleDisplayTypeClick = (dt: DisplayType) => () => {
        setSelectedDisplayType(dt);
    };
    
    const handleStatTypeClick = (st: StatRecordType) => () => {
        setSelectedStatType(st);
    };
    
    const updateSelectedExercise = (ev: React.FormEvent<HTMLSelectElement>) => {
        setSelectedExercise(ev.currentTarget.value);
    };
    
    return (<>
    { isLoading && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>Stats</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <div className="pure-u-1">
                <VictoryChart
                    maxDomain={{ y: 1000 }}
                    containerComponent={
                      <VictoryVoronoiContainer
                        labels={(data: LabelParam) => (selectedStatType === StatRecordType.SETS ? data.datum.y + ' lbs' : data.datum.y.toString())}
                        labelComponent={
                          <VictoryTooltip  dy={-7} pointerLength={30} constrainToVisibleArea />
                        }
                        voronoiBlacklist={["line"]}
                      />
                    }
                    animate={{duration: 500}}
                >
                  <VictoryLine name="line"
                    data={data}
                  />
                  <VictoryScatter
                    style={{ data: { fill: "#c43a31" } }}
                    size={7}
                    data={data}
                  />
                </VictoryChart>
            </div>
            <div className="pure-u-1">
                <HRule />
            </div>
            <div className="pure-u-1">
                <div className={classNames("pure-button-group", styles.buttonGroup)} role="group">
                    <button className={classNames("pure-button", { "pure-button-primary": (selectedStatType === StatRecordType.SETS)})}
                        onClick={handleStatTypeClick(StatRecordType.SETS)}
                    >
                        Sets
                    </button>
                    <button className={classNames("pure-button", { "pure-button-primary": (selectedStatType === StatRecordType.REPS)})}
                        onClick={handleStatTypeClick(StatRecordType.REPS)}
                    >
                       Reps 
                    </button>
                </div>
            </div>
            <div className="pure-u-1">
                <HRule />
            </div>
            <div className="pure-u-1">
                <div className={classNames("pure-button-group", styles.buttonGroup)} role="group">
                    <button className={classNames("pure-button", { "pure-button-primary": (selectedDisplayType === DisplayType.Average)})}
                        onClick={handleDisplayTypeClick(DisplayType.Average)}
                    >
                        Average
                    </button>
                    <button className={classNames("pure-button", { "pure-button-primary": (selectedDisplayType === DisplayType.Max)})}
                        onClick={handleDisplayTypeClick(DisplayType.Max)}
                    >
                        Max 
                    </button>
                </div>
            </div>
            <div className={classNames("pure-form pure-u-1", styles.selectExerciseContainer)}>
                <select className="pure-input-1" value={selectedExercise || undefined}
                    onChange={updateSelectedExercise}
                >
                    { exercises.map((exercise) => (
                        <option key={exercise.exerciseId} value={exercise.exerciseId}>{exercise.exerciseName}</option>
                    ))}
                </select>
            </div>
        </section>
    </div>
    </>);
};

export default Workouts;