import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import styles from './weight.module.scss';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import HRule from '../../components/HRule/HRule';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import useGoals from '../../store/goals/hooks/useGoals';
import { Link } from 'react-router-dom';
import weightSlice from '../../store/weight/weight-slice';
import { weightStateSelector } from '../../store/weight/weight-selectors';
import { saveWeight } from '../../store/weight/weight-actions';
import useSession from '../../custom_hooks/useSession';
import { VictoryChart, VictoryLine, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { statsSelector } from '../../store/userData/user-data-selectors';
import StatCalculator, { ChartItem } from '../../models/StatCalculator';
import StatRecord, { StatRecordType } from '../../models/StatRecord';
import { getStatRecords } from '../../store/userData/user-data-actions';
import { compareDesc, format } from 'date-fns';

type DataType = { x: number; y: number; };
type LabelParam = { datum: DataType; }

const Weight  = () => {
    const dispatch = useDispatch();
    const { goals } = useGoals();
    const {
        currentWeight,
        isLoading,
    } = useSelector(weightStateSelector);
    const {
        statRecords,
        isLoading: isLoadingStats,
        hasLoadedStatRecords,
    } = useSelector(statsSelector);
    const { user } = useSession();
    
    const {
        updateFormWeight,
    } = weightSlice.actions;
    
    const targetWeight = goals?.targetWeight;

    let data: ChartItem[] = [];
    let lastRecord: StatRecord | null = null;

    useEffect(() => {
        if (!(isLoadingStats || hasLoadedStatRecords)) {
            dispatch(getStatRecords(user));
        }
    }, []);
    
    if (hasLoadedStatRecords) {
        const calculator = new StatCalculator(statRecords, StatRecordType.WEIGHT);
        data = calculator.getWeight();
        
        if (data.length) {
            lastRecord = statRecords
            .filter((r) => r.statRecordType === StatRecordType.WEIGHT)
            .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))[0];
        }
    }
    
    const updateCurrentWeight = (ev:React.FormEvent<HTMLInputElement>) => {
        dispatch(updateFormWeight(ev.currentTarget.value));
    }
    
    const saveCurrentWeight = (ev:React.FormEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        dispatch(saveWeight(user, currentWeight));
    };

    return (<>
    { (isLoading || isLoadingStats) && <LoadingWithOverlay contained={false} />}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>Weight</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            { targetWeight && (
                <p className={classNames("pure-u-1", styles.targetWeight)}>Target Weight: <strong>{targetWeight} lbs</strong></p>
            ) }
            { !targetWeight && (
                <p className={classNames("pure-u-1", styles.targetWeight)}>
                    Set your target weight in your <Link to="/goals">Goals</Link>
                </p>
            ) }
            { lastRecord && (
                <p className={classNames("pure-u-1", styles.targetWeight)}>Last Weight: <strong>{lastRecord.value} lbs</strong> on {format(new Date(lastRecord.date), 'MMM d, yyyy')}</p>
            ) }
        
            <div className="pure-u-1">
                <HRule />
            </div>
        
            <section className={classNames("pure-u-1", styles.formSection)}>
                <form className={classNames("pure-form pure-form-stacked", styles.form)}>
                    <fieldset className={classNames("pure-g", styles.fieldset)}>
                        <label className="pure-u-1" htmlFor="currentWeight">Current Weight</label>
                        <input className="pure-u-1-3" type="number" id="currentWeight" placeholder="lbs"
                            onChange={updateCurrentWeight}
                            value={currentWeight}
                         />
                        <div className={classNames("pure-u-1-3", styles.buttonContainer)}>
                            <button  type="submit" className="pure-button pure-button-primary"
                                onClick={saveCurrentWeight}
                            >
                                Save
                            </button>
                        </div>
                    </fieldset>
                </form>
            </section>
            
            <div className="pure-u-1">
                <HRule />
            </div>
            
            <div className="pure-u-1">
                <VictoryChart
                    maxDomain={{ y: 1000 }}
                    containerComponent={
                      <VictoryVoronoiContainer
                        labels={(data: LabelParam) => (data.datum.y.toString() + ' lbs')}
                        labelComponent={
                          <VictoryTooltip  dy={-7} pointerLength={30} constrainToVisibleArea />
                        }
                        voronoiBlacklist={["line"]}
                      />
                    }
                    animate={{duration: 500}}
                >
                  <VictoryLine name="line"
                    data={data}
                  />
                  <VictoryScatter
                    style={{ data: { fill: "#c43a31" } }}
                    size={7}
                    data={data}
                  />
                </VictoryChart>
            </div>
        </section>
    </div>
    </>);
};

export default Weight;