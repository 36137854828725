import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TrainerExerciseProgram from '../../../../models/TrainerExerciseProgram';
import styles from './list-card-icon.module.scss';

interface ListCardIconProps {
    selectedProgram?: string;
    program: TrainerExerciseProgram;
} 

const ListCardIcon = ({ program, selectedProgram }: ListCardIconProps) => {
    if (program.exerciseProgramPath === selectedProgram) {
        return <span className={styles.iconContainer}><FontAwesomeIcon icon={['far', 'check-circle']} size="lg" /></span>
    }

    return <FontAwesomeIcon icon={['far', 'circle']} size="lg" />;
};

export default ListCardIcon;
