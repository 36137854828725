import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trainersStateSelector } from '../trainers-selectors';
import { getTrainers} from '../trainers-actions';

const useTrainers = function useClients() {
    const dispatch = useDispatch();
    const {
        hasLoaded,
        isLoading,
        requestError,
        trainers,
    } = useSelector(trainersStateSelector);
    
    useEffect(() => {
      if (!(hasLoaded || isLoading)) {
        dispatch(getTrainers());
      }
    }, []); 
    
    return {
        hasLoaded,
        isLoading,
        requestError,
        trainers,
    };
}

export default useTrainers;