import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Route from './RouteWrapper';
import { AnimatedSwitch } from 'react-router-transition';

import styles from './routes.module.scss';
import SignIn from '../pages/SignIn/SignIn';
import SignUpModel from '../pages/SignUp/SignUp';
import Home from '../pages/Home/Home';
import Week from '../pages/Week/Week';
import Workouts from '../pages/Workouts/Workouts';
import Details from '../pages/Details/Details';
import History from '../pages/History/History';
import Badges from '../pages/Badges/Badges';
import Stats from '../pages/Stats/Stats';
import Inactive from '../pages/Inactive/Inactive';
import PreLoader from '../pages/PreLoader/PreLoader';
import PLExercisePrograms from '../pages/PLExercisePrograms/PLExercisePrograms';
import Trainers from '../pages/Trainers/Trainers';
import PLTrainers from '../pages/PLTrainers/PLTrainers';
import ExercisePrograms from '../pages/ExercisePrograms/ExercisePrograms';
import Messages from '../pages/Messages/Messages';
import Weight from '../pages/Weight/Weight';
import Goals from '../pages/Goals/Goals';


const Routes = () => (
  <AnimatedSwitch 
    atEnter={{ opacity: 0 }}
    atLeave={{ opacity: 0 }}
    atActive={{ opacity: 1 }}
    className={styles.switchWrapper}
  >
    <Route path="/" exact component={Home} isPrivate />
    <Route path="/stats" exact component={Stats} isPrivate />
    <Route path="/history" exact component={History} isPrivate />
    <Route path="/details/:exerciseId" exact component={Details} isPrivate />
    <Route path="/week" exact component={Week} isPrivate />
    <Route path="/workouts/:splitDay" exact component={Workouts} isPrivate />
    <Route path="/workouts" exact component={Workouts} isPrivate />
    <Route path="/preloader" exact component={PreLoader} isPrivate />
    <Route path="/pl-trainers" exact component={PLTrainers} isPrivate />
    <Route path="/pl-exercise-programs" exact component={PLExercisePrograms} isPrivate />
    <Route path="/sign-in" exact component={SignIn} />
    <Route path="/sign-up" exact component={SignUpModel} />
    <Route path="/inactive" exact component={Inactive} />
    <Route path="/trainers" exact component={Trainers} isPrivate />
    <Route path="/exercise-programs" exact component={ExercisePrograms} isPrivate />
    <Route path="/messages" exact component={Messages} isPrivate />
    <Route path="/weight" exact component={Weight} isPrivate />
    <Route path="/goals" exact component={Goals} isPrivate />
    <Redirect to="/" />
  </AnimatedSwitch>
);

export default Routes;
