import { v4 as createUUID } from 'uuid';
import api from '../../api';
import { showError, showSuccess } from '../../alert/alert';
import { AppThunk } from '../index';
import userDataSlice from './user-data-slice';
import { PreferenceField } from '../../models/Preferences';
import User from '../../models/User';
import ClientEvent, { ClientEventType, generateEventCategory } from '../../models/ClientEvent';
import Exercise, { ExerciseUnit } from '../../models/Exercise';
import StatRecord, { generateStatRecordCategory, StatRecordType } from '../../models/StatRecord';
import HashMap from '../../models/HashMap';

export const getUserData = (user: User): AppThunk => async(dispatch) => {
  const {
    userDataRequested,
    userDataRequestSucceeded,
    userDataRequestFailed,
  } = userDataSlice.actions;

  dispatch(userDataRequested());
  
  try {
    const userData = await api.userData.getUserData(user);
    dispatch(userDataRequestSucceeded(userData));
  } catch(e) {
    dispatch(userDataRequestFailed(e.message));
    showError('Error Loading Your Data');
  }
};

export const savePreference = (
  user: User,
  field: PreferenceField,
  value: string | number | Array<string|number>,
  onComplete?: () => void,
): AppThunk => async(dispatch) => {
  const {
    savePreferenceRequested,
    savePreferenceRequestSucceeded,
    savePreferenceRequestFailed,
  } = userDataSlice.actions;

  dispatch(savePreferenceRequested());
  
  try {
    await api.userData.savePreference(user, field, value);
    
    dispatch(getUserData(user));

    dispatch(savePreferenceRequestSucceeded());
    
    if (onComplete) {
      onComplete();
    }

    showSuccess(`${field} saved`);
  } catch(e) {
    dispatch(savePreferenceRequestFailed(e.message));
    showError('Error Loading Your Data');
  }
};

const createStatRecord = (set: number, value: string, statCategory: string, exercise: Exercise) => {
  const today = new Date();
  return {
    date: today.toString(),
    exerciseId: exercise.exerciseId,
    statCategory,
    statRecordId: createUUID(),
    unit: exercise.unit || ExerciseUnit.lbs,
    value: parseInt(value),
    statRecordType: StatRecordType.SETS,
  } as StatRecord;
}

const createRepsRecord = (set: number, value: string, statCategory: string, exercise: Exercise) => {
  const today = new Date();
  return {
    date: today.toString(),
    exerciseId: exercise.exerciseId,
    statCategory,
    statRecordId: createUUID(),
    value: parseInt(value),
    statRecordType: StatRecordType.REPS,
  } as StatRecord;
}

export const completeExercise = (
  user: User,
  exercise: Exercise,
  sets?: HashMap<number, string>,
  reps?: HashMap<number, string>,
  onComplete?: () => void,
): AppThunk => async (dispatch) => {
  const {
    completeExerciseRequested,
    completeExerciseRequestSucceeded,
    completeExerciseRequestFailed,
  } = userDataSlice.actions;

  dispatch(completeExerciseRequested());
  
  const today = new Date();
  
  const completeExerciseEvent = {
    date: today.toString(),
    description: `Completed Exercise ${exercise.exerciseName}`,
    clob: JSON.stringify({ exerciseId: exercise.exerciseId }),
    eventCategory: generateEventCategory(today),
    eventId: createUUID(),
    eventType: ClientEventType.WORKOUT_COMPLETE,
  } as ClientEvent;
  
  const statRecordCategory = generateStatRecordCategory(today);

  let setStatRecords;
  if (sets) {
    setStatRecords = sets.map(([k, v]) => createStatRecord(k, v, statRecordCategory, exercise));
  }
  
  let repsStatRecords;
  if (reps) {
    repsStatRecords = reps.map(([k, v]) => createRepsRecord(k, v, statRecordCategory, exercise));
  }
  
  try {
    await api.userData.savePreference(user, PreferenceField.lastExercise, exercise.exerciseId);
    await api.userData.saveClientStreamEvent(user, completeExerciseEvent);
    
    if (setStatRecords) {
      await api.userData.saveClientStatRecord(user, statRecordCategory, setStatRecords);
    }
    
    if (repsStatRecords) {
      await api.userData.saveClientStatRecord(user, statRecordCategory, repsStatRecords);
    }
    
    dispatch(getUserData(user));
    dispatch(getStatRecords(user));

    dispatch(completeExerciseRequestSucceeded());
    
    if (onComplete) {
      onComplete();
    }

    showSuccess(`${exercise.exerciseName} completed!`);
  } catch(e) {
    dispatch(completeExerciseRequestFailed(e.message));
    showError('Error Saving your progress');
  }
}

export const getStatRecords = (user: User): AppThunk => async(dispatch) => {
  const {
    statRecordsRequested,
    statRecordsRequestSucceeded,
    statRecordsRequestFailed,
  } = userDataSlice.actions;

  dispatch(statRecordsRequested());
  
  try {
    const statRecords = await api.userData.getStatRecords(user);
    dispatch(statRecordsRequestSucceeded(statRecords));
  } catch(e) {
    dispatch(statRecordsRequestFailed(e.message));
    showError('Error Loading Your Data');
  }
};

export const getUserSelected = (): AppThunk => async(dispatch) => {
  const {
    userSelectedRequested,
    userSelectedRequestSucceeded,
    userSelectedRequestFailed,
  } = userDataSlice.actions;

  dispatch(userSelectedRequested());
  
  try {
    const userSelected = await api.userData.getUserSelected();
    dispatch(userSelectedRequestSucceeded(userSelected));
  } catch(e) {
    dispatch(userSelectedRequestFailed(e.message));
    showError('Error Loading Your Data');
  }
};

export const updateSelectedTrainer = (user: User, trainerId: string): AppThunk => async(dispatch) => {
  const {
    updateUserSelectedFailed,
    updateUserSelectedRequested,
    updateUserSelectedSucceeded,
  } = userDataSlice.actions;

  dispatch(updateUserSelectedRequested());
  
  try {
    const userSelected = await api.userData.setSelectedTrainer(user, trainerId);
    dispatch(updateUserSelectedSucceeded(userSelected));
  } catch(e) {
    dispatch(updateUserSelectedFailed(e.message));
    showError('Error Saving Your Data');
  }
}

export const updateSelectedExerciseProgram = (
  user: User,
  exerciseProgram: string,
  onComplete?: () => void,
): AppThunk => async(dispatch) => {
  const {
    updateUserSelectedFailed,
    updateUserSelectedRequested,
    updateUserSelectedSucceeded,
  } = userDataSlice.actions;

  dispatch(updateUserSelectedRequested());
  
  try {
    const userSelected = await api.userData.setSelectedExerciseProgram(user, exerciseProgram);
    
    if (onComplete) {
      onComplete();
    }
    
    dispatch(updateUserSelectedSucceeded(userSelected));
  } catch(e) {
    dispatch(updateUserSelectedFailed(e.message));
    showError('Error Saving Your Data');
  }
}