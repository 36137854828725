import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import UserData from '../../models/UserData';
import Preferences from '../../models/Preferences';
import StatRecord from '../../models/StatRecord';
import UserSelected from '../../models/UserSelected';
import { statsSelector } from './user-data-selectors';

interface UserDataState {
  userData: UserData;
  isLoading: boolean;
  requestError: string;
  hasLoadedUserData: boolean;
  statRecords: StatRecord[];
  hasLoadedStatRecords: boolean;
  hasLoadedUserSelected: boolean;
  userSelected: UserSelected;
}

const userDataSlice = createSlice({
  name: 'userDataSlice',
  initialState: {
    isLoading: false,
    hasLoadedUserData: false,
    hasLoadedStatRecords: false,
  } as UserDataState,
  reducers: {
    userDataRequested: (state) => {
      state.isLoading = true;
    },
    userDataRequestSucceeded: (state, action: PayloadAction<UserData>) => {
      state.isLoading = false;
      state.userData = action.payload;
      state.requestError = '';
      state.hasLoadedUserData = true; 
    }, 
    userDataRequestFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
      state.hasLoadedUserData = false; 
    }, 
    savePreferenceRequested: (state) => {
      state.isLoading = true;
    },
    savePreferenceRequestSucceeded: (state) => {
      state.isLoading = false;
      state.requestError = '';
    }, 
    savePreferenceRequestFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
    }, 
    completeExerciseRequested: (state) => {
      state.isLoading = true;
    },
    completeExerciseRequestSucceeded: (state) => {
      state.isLoading = false;
      state.requestError = '';
    }, 
    completeExerciseRequestFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
    }, 
    statRecordsRequested: (state) => {
      state.isLoading = true;
    },
    statRecordsRequestSucceeded: (state, action: PayloadAction<StatRecord[]>) => {
      state.isLoading = false;
      state.requestError = '';
      state.statRecords = action.payload;
      state.hasLoadedStatRecords = true;
    }, 
    statRecordsRequestFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
      state.hasLoadedStatRecords = false;
    }, 
    userSelectedRequested: (state) => {
      state.isLoading = true;
    },
    userSelectedRequestSucceeded: (state, action: PayloadAction<UserSelected>) => {
      state.isLoading = false;
      state.requestError = '';
      state.userSelected = action.payload;
      state.hasLoadedUserSelected = true;
    },
    userSelectedRequestFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
    },
    updateUserSelectedRequested: (state) => {
      state.isLoading = true;
    },
    updateUserSelectedSucceeded: (state, action: PayloadAction<UserSelected>) => {
      state.isLoading = false;
      state.userSelected = action.payload;
    },
    updateUserSelectedFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.requestError = action.payload;
    },
  }
});

export default userDataSlice;
