import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './sign-in.module.scss';
import { Link, Redirect } from 'react-router-dom';
import useSession from '../../custom_hooks/useSession';
import { signIn } from '../../store/auth/auth-actions';
import { signInFormSelector  } from '../../store/auth/auth-selectors';
import authSlice from '../../store/auth/auth-slice';
import SignInField from '../../models/SignInField.enum';
import FormError from '../../components/FormError/FormError';

// @TODO from api
const signInImageSource = 'images/sign-in.jpg';
const headerMessage = 'Welcome Back!';

const Login  = () => {
    const { isSignedIn } = useSession();
    const [showErrors, setShowErrors] = useState(false);
    const dispatch = useDispatch();

    const {
        form,
        isLoading,
    } = useSelector(signInFormSelector);

    const { updateSignInForm } = authSlice.actions;

    const requiredFields = [SignInField.email, SignInField.password];

    const handleSignInClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const isValid = requiredFields.reduce((valid, field) => {
            return valid && !!form.get(field);
        }, true);
        
        if (isValid) {
            setShowErrors(false);
            dispatch(signIn(
                form.getWithDefault(SignInField.email, ''),
                form.getWithDefault(SignInField.password ,'')
            ));
        } else {
            setShowErrors(true);
        }
        
        event.preventDefault();
    };

    const handleFieldChange = (field: SignInField) => (ev: React.FormEvent<HTMLInputElement>) => {
        dispatch(updateSignInForm({
            field,
            value: ev.currentTarget.value,
        }));
    };

    const getError = (field: SignInField) => {
        if (showErrors && !form.getWithDefault(field, '')) {
            return 'required';
        }
    };
    
    if (isSignedIn) return <Redirect to="/preloader" />;

    return (<div className={styles.container}>
        <section className={styles.topSectionImageContainer}>
            <img src={signInImageSource} alt="Sign In" />
        </section>
        <section className={styles.topSection}>
        </section>
        <section className={styles.bottomSection}>
            <form className="pure-form">
                <fieldset className={classNames(styles.signInFieldset)}>
                    <legend>
                        <h1>{headerMessage}</h1>
                    </legend>
                    <input id="email" type="email" className="pure-input-1" placeholder="Email"
                        onChange={handleFieldChange(SignInField.email)}
                        value={form.getWithDefault(SignInField.email, '')}
                        required
                    />
                    <FormError errorMessage={getError(SignInField.email)} />
                    <input id="password" type="password" className="pure-input-1" placeholder="Password"
                        onChange={handleFieldChange(SignInField.password)}
                        value={form.getWithDefault(SignInField.password, '')}
                        required
                    />
                    <FormError errorMessage={getError(SignInField.password)} />
                    <button
                        type="submit"
                        className={classNames("pure-button pure-input-1 pure-button-primary", styles.signInButton)}
                        onClick={handleSignInClick}
                    >Sign in</button>
                </fieldset>
            </form>
            <div className={styles.signUpLinkContainer}>
                <Link to="/sign-up" className={styles.signUpLink}>Sign Up</Link>
            </div>
        </section>
    </div>);
};

export default Login;