import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import styles from './home.module.scss';
import Wave, { FillColor } from '../../components/Wave/Wave';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '../../store/auth/auth-actions';
import { userDataStateSelector, nextExerciseSelector } from '../../store/userData/user-data-selectors';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import useSession from '../../custom_hooks/useSession';
import ListCard from '../../components/ListCard/ListCard';
import UserStatus from '../../models/UserStatus.enum';
import useUserData from '../../store/userData/hooks/useUserData';
import useUserSelected from '../../store/userData/hooks/useUserSelected';

const Home  = () => {
    const dispatch = useDispatch();
    const { user } = useSession();
    const [exerciseClicked, setExerciseClicked] = useState(false);
    
    const {
        hasLoadedUserSelected,
        userSelected,
    } = useUserSelected();
    
    const {
        hasLoadedUserData,
        userData
    } = useUserData();
    
    const nextExercise = useSelector(nextExerciseSelector);

    const handleSignOut= () => {
        dispatch(signOut());
        return false;
    };
    
    const workoutRedirect = hasLoadedUserSelected && userSelected.selectedExerciseProgram
        ? `/workouts/${nextExercise?.splitDay}`
        : '/exercise-programs';
    
    if (hasLoadedUserData && userData.status === UserStatus.inactive) {
        return <Redirect to={`/inactive`} />;
    }
    
    if (exerciseClicked) {
        return <Redirect to={`/details/${nextExercise?.exerciseId}`} />;
    }
        
    return (<>
    { !hasLoadedUserData && (
        <LoadingWithOverlay contained={false} />
    )}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <div className={styles.waveContainer}>
                <Wave fillColor={FillColor.white} />
            </div>
            <h1>Welcome {user.displayName}!</h1>
        </section>
        <section className={styles.bottomSection}>
            { nextExercise && (<div className={styles.bottomItemContainer}>
                <p className={styles.message} onClick={() => { setExerciseClicked(true); }}>
                    <span>
                        Your next exercise is
                        <Link to={`/details/${nextExercise?.exerciseId}`} className={styles.messageLink}>{nextExercise?.exerciseName}</Link>!
                    </span>
                    <FontAwesomeIcon icon={['fas', 'caret-right']} />
                </p>
            </div>)}
            <div className={styles.iconContainer}>
                <Link to={workoutRedirect} className={styles.iconLink}>
                    <FontAwesomeIcon icon={['fas', 'burn']} size="4x" />
                    Start 
                </Link>
                <Link to='/week' className={styles.iconLink}>
                    <FontAwesomeIcon icon={['fas', 'fire-alt']} size="4x" />
                    Workouts
                </Link>
            </div>
            <div className={styles.iconContainer}>
                <Link to="/weight" className={styles.iconLink}>
                    <FontAwesomeIcon icon={['fas', 'weight']} size="4x" />
                    Weight
                </Link>
                <Link to="/messages" className={styles.iconLink}>
                    <FontAwesomeIcon icon={['fas', 'comment-dots']} size="4x" />
                    Message
                </Link>
            </div>
        </section>
        <div className={styles.utilContainer}>
            <Link to="/goals" className={styles.utilButton}>
                <FontAwesomeIcon icon={['fas', 'check-double']} size="3x" />
            </Link>
            <Link to="/stats" className={styles.utilButton}>
                <FontAwesomeIcon icon={['fas', 'chart-line']} size="3x" />
            </Link>
            <Link to="/trainers" className={styles.utilButton}>
                <FontAwesomeIcon icon={['fas', 'address-book']} size="3x" />
            </Link>
            <Link to="/exercise-programs" className={styles.utilButton}>
                <FontAwesomeIcon icon={['fas', 'book']} size="3x" />
            </Link>
            <a className={styles.utilButton} onClick={handleSignOut}>
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} size="3x" />
            </a>
        </div>
    </div>
    </>);
};

export default Home;