export enum PreferenceField {
    selectedExerciseProgram = 'selectedExerciseProgram',
    lastExercise = 'lastExercise',
}

interface Preferences {
    selectedExerciseProgram?: string;
    lastExercise?: string;
}

export default Preferences;