import api from '../../api';
import { showError } from '../../alert/alert';
import { AppThunk } from '../index';
import messagesSlice from './messages-slice';
import User from '../../models/User';


export const subscribeToMessages = (user: User) : AppThunk => async(dispatch) => {
  const {
    subscribedToMessages,
    messagesReceived,
    messagesSubscriptionFailed,
  } = messagesSlice.actions;

  try {
    const subscriptionId = await api.message.subscribeToMessages(user, (messages) => {
      dispatch(messagesReceived(messages));
    });
    dispatch(subscribedToMessages(subscriptionId));
  } catch(e) {
    dispatch(messagesSubscriptionFailed(e.message));
  }
}
      
export const unsubscribeFromMessages = (subscriptionId: string): AppThunk => async(dispatch) => {
  const {
    unsubscribedFromMessages,
  } = messagesSlice.actions;

  api.message.unsubscribeFromMessages(subscriptionId);
  
  dispatch(unsubscribedFromMessages());
}

export const addMessage = (user: User, trainerDisplayName: string, trainerId: string, message: string): AppThunk => async(dispatch) => {
  const {
    messageUpdateRequested,
    messageUpdateCompleted,
    messageUpdateFailed,
  } = messagesSlice.actions;
  

  dispatch(messageUpdateRequested());
  
  try {
    await api.message.addMessage(user, trainerDisplayName, trainerId, message);
    
    dispatch(messageUpdateCompleted());
  } catch(e) {
    dispatch(messageUpdateFailed(e.message));
  }
};