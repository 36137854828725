import api from '../../api';
import { showError, showSuccess } from '../../alert/alert';
import { AppThunk } from '../index';
import goalsSlice from './goals-slice';
import User from '../../models/User';
import Goals from '../../models/Goals';


export const getGoals = (user: User) : AppThunk => async(dispatch) => {
  const {
    goalsRequested,
    goalsRequestSucceeded,
    goalsRequestFailed,
  } = goalsSlice.actions;
  
  dispatch(goalsRequested());
  
  try {
    const goals = await api.goals.getGoals(user);
    dispatch(goalsRequestSucceeded(goals));
  } catch(e) {
    dispatch(goalsRequestFailed(e.message));
    showError('Error Retrieving Goals');
  }
};

export const updateGoals = (user: User, goals: Goals) : AppThunk => async(dispatch) => {
  const {
    goalsUpdateRequested,
    goalsUpdateCompleted,
    goalsUpdateFailed,
  } = goalsSlice.actions;
  
  dispatch(goalsUpdateRequested());
  
  try {
    await api.goals.updateGoals(user, goals);
    dispatch(goalsUpdateCompleted(goals));
    showSuccess('Goals Saved');
  } catch(e) {
    dispatch(goalsUpdateFailed(e.message));
    showError('Error Updating Goals');
  }
};
