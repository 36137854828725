import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './home-icon-link.module.scss';

interface HomeIconLinkProps {
    path: string;
}

const HomeIconLink = ({ path }: HomeIconLinkProps) => {
    return (
        <Link to={path} className={styles.link}>
            <FontAwesomeIcon icon={['fas', 'home']} size="lg" />
        </Link>
    );
};

export default HomeIconLink;