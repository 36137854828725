import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import { setSourceMapRange } from 'typescript';
import HashMap from '../../models/HashMap';
import User from '../../models/User';

type ExerciseId = string;
type ExerciseSetKey = number;

interface ExerciseState {
  exerciseSetValues: HashMap<ExerciseId, HashMap<ExerciseSetKey, string>>;
  exerciseRepsValues: HashMap<ExerciseId, HashMap<ExerciseSetKey, string>>;
  exerciseSetSelected: HashMap<ExerciseId, HashMap<ExerciseSetKey, boolean>>;
}

interface ExerciseSetData {
  exerciseId: ExerciseId;
  setKey: ExerciseSetKey;
  value: string;
}
interface ExerciseSetSelectedData {
  exerciseId: ExerciseId;
  setKey: ExerciseSetKey;
  value: boolean;
}

const exerciseStateSlice = createSlice({
  name: 'exerciseState',
  initialState: {
    exerciseSetValues: new HashMap<ExerciseId, HashMap<ExerciseSetKey, string>> (),
    exerciseRepsValues: new HashMap<ExerciseId, HashMap<ExerciseSetKey, string>>(),
    exerciseSetSelected: new HashMap<ExerciseId, HashMap<ExerciseSetKey, boolean>> (),
  } as ExerciseState,
  reducers: {
    updateExerciseSetValue(state, action: PayloadAction<ExerciseSetData>) {
      const {
        exerciseId,
        setKey,
        value,
      } = action.payload;
      
      const exerciseSetValues = state.exerciseSetValues.clone();
      const setValues = exerciseSetValues.get(exerciseId) || new HashMap<ExerciseSetKey, string>();
      
      setValues.set(setKey, value)
      exerciseSetValues.set(exerciseId, setValues);

      state.exerciseSetValues = exerciseSetValues;
    },
    clearExerciseSetValues(state) {
      state.exerciseSetValues.clear();
    },
    updateExerciseSetSelected(state, action: PayloadAction<ExerciseSetSelectedData>) {
      const {
        exerciseId,
        setKey,
        value,
      } = action.payload;

      const exerciseSetSelected = state.exerciseSetSelected.clone();
      const setSelected = exerciseSetSelected.get(exerciseId) || new HashMap<ExerciseSetKey, boolean>();
      
      setSelected.set(setKey, value)
      exerciseSetSelected.set(exerciseId, setSelected);

      state.exerciseSetSelected = exerciseSetSelected;
    },
    clearExerciseSetSelected(state) {
      state.exerciseSetSelected.clear();
    },
    updateExerciseRepValue(state, action: PayloadAction<ExerciseSetData>) {
      const {
        exerciseId,
        setKey,
        value,
      } = action.payload;
      
      const exerciseRepsValues = state.exerciseRepsValues.clone();
      const setValues = exerciseRepsValues.get(exerciseId) || new HashMap<ExerciseSetKey, string>();
      
      setValues.set(setKey, value)
      exerciseRepsValues.set(exerciseId, setValues);

      state.exerciseRepsValues = exerciseRepsValues;
    },
    clearExerciseRepValues(state) {
      state.exerciseRepsValues.clear();
    },
  }
});

export default exerciseStateSlice;
