import React from 'react';

export enum FillColor {
    primary,
    secondary,
    white
}

interface WaveProps {
    fillColor: FillColor;
}

const Wave = ({ fillColor }: WaveProps) => {
    const fill = fillColor === FillColor.primary
        ? '#336699ff'
        : fillColor === FillColor.white
        ? '#ffffffff'
        : '#86bbd8ff'; // secondary

    return (
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}>
            <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: "none", fill }}></path>
        </svg>
    );
};


export default Wave;