import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import Goals from '../models/Goals';
import User from '../models/User';
import { getClientColl } from './helpers';

enum DataPaths {
    goals = 'goals',
}

const GoalAPI = {
    getGoals: async (user: User) => {
        const clientColl = getClientColl(user.email);
        const doc = await clientColl.doc(DataPaths.goals).get();
        
        const goals = doc.data() as Goals;
        
        return goals;
    },

    updateGoals: (user: User, goals: Goals) => {
        const clientColl = getClientColl(user.email);
        const goalsDoc = clientColl.doc(DataPaths.goals);

        goalsDoc.set({
            ...goals,
        }, { merge: true })
    },

};


export default GoalAPI;
