// https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react

// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faListAlt, faFireAlt, faChartLine, faCommentDots, faCaretRight, faArrowLeft, faHome,
    faInfoCircle, faCircle as faCircleSolid, faFire, faRibbon, faSignOutAlt, faBurn,
    faRunning, faBan, faVideo, faAddressBook, faBook, faWeight, faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';

import { faCircle, faCheckCircle, faCalendarCheck, faAddressCard } from '@fortawesome/free-regular-svg-icons';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';

library.add(
    faListAlt,
    faFireAlt,
    faChartLine,
    faCommentDots,
    faCaretRight,
    faArrowLeft,
    faHome,
    faInfoCircle,
    faCircle,
    faCheckCircle,
    faCircleSolid,
    faFire,
    faRibbon,
    faSignOutAlt,
    faBurn,
    faRunning,
    faCalendarCheck,
    faBan,
    faYoutube,
    faVideo,
    faAddressCard,
    faAddressBook,
    faBook,
    faWeight,
    faCheckDouble,
);