import { NumberLiteralType } from "typescript";
import StatRecord, { StatRecordType } from "./StatRecord";
import HashMap from './HashMap';
import { format } from "date-fns";

export interface ChartItem { x: string | number; y: number };

class StatCalculator {
    private statRecordType: StatRecordType;
    private records: StatRecord[];
    private exerciseIdToRecords = new  HashMap<string, string[]>();

    constructor(records: StatRecord[], recordType: StatRecordType) {
        this.records = records.filter(record => record.statRecordType === recordType);
        this.statRecordType = recordType;

        this.setExerciseIdToRecordIdMap();
    }
    
    setExerciseIdToRecordIdMap() {
        this.exerciseIdToRecords = this.records.reduce((exerciseRecords, record) => {
           const prev  = exerciseRecords.getWithDefault(record.exerciseId, []); 
           
           exerciseRecords.set(record.exerciseId, [
               ...prev,
               record.statRecordId,
           ]);
            
           return exerciseRecords;
        }, new HashMap<string, string[]>());
    }
    
    private toCategoryMap(records: StatRecord[]) {
        return records
        .reduce((exerciseRecords, record) => {
           const prev  = exerciseRecords.getWithDefault(record.statCategory, []); 
           
           exerciseRecords.set(record.statCategory, [
               ...prev,
               record,
           ]);
            
           return exerciseRecords;
        }, new HashMap<string, StatRecord[]>());
    }

    // Exercises Calcs
    getAverages(exerciseId: string) {
        const filtered = this.exerciseIdToRecords.filter(([recExerciseId]) => recExerciseId === exerciseId);

        const averages = filtered.map(([exerciseId, recordIds]) => {
            const exerciseRecords = this.records.filter(record => recordIds.indexOf(record.statRecordId) > -1);
            const catToRecords = this.toCategoryMap(exerciseRecords);
            
            // Category Averages
            const categoryAvgs = catToRecords.map(([category, records]) => {
                const total = records.reduce((t, rec) => t + rec.value, 0);
                const avg = total / records.length;
                const date = new Date(records[0].date);
                
                return { x: format(date, 'M-d-yy'), y: avg } as ChartItem;
            });
            
            return { exerciseId, categoryAvgs }
        }).reduce((hm, {exerciseId, categoryAvgs}) => {
            hm.set(exerciseId, categoryAvgs)
            return hm;
        }, new HashMap<string, ChartItem[]>());
        
        return averages.get(exerciseId);
    }

    getMaxes(exerciseId: string) {
        const filtered = this.exerciseIdToRecords.filter(([recExerciseId]) => recExerciseId === exerciseId);

        const maxes = filtered.map(([exerciseId, recordIds]) => {
            const exerciseRecords = this.records.filter(record => recordIds.indexOf(record.statRecordId) > -1);
            const catToRecords = this.toCategoryMap(exerciseRecords);
            
            // Category Maxes 
            const chartItems = catToRecords.map(([category, records]) => {
                const max = records.reduce((t, rec) => t > rec.value ? t : rec.value, 0);
                const date = new Date(records[0].date);
                
                return { x: format(date, 'M-d-yy'), y: max } as ChartItem;
            });
            
            return { exerciseId,   chartItems };
        }).reduce((hm, {exerciseId, chartItems}) => {
            hm.set(exerciseId, chartItems)
            return hm;
        }, new HashMap<string, ChartItem[]>());
        
        return maxes.get(exerciseId);
    }
    
    getWeight() {
        return this.records.map(record => {
            return {x: format(new Date(record.date), 'M-d-yy'), y: record.value};
        }) as ChartItem[];
    }
}

export default StatCalculator;