import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goalsStateSelector } from '../goals-selectors';
import { getGoals } from '../goals-actions';
import useSession from '../../../custom_hooks/useSession';

const useGoals = function useClients() {
    const dispatch = useDispatch();
    const {
        isLoading,
        hasLoadedGoals,
        goals,
        requestError,
    } = useSelector(goalsStateSelector);
    const { user } = useSession();
    
    useEffect(() => {
      if (!(hasLoadedGoals || isLoading)) {
        dispatch(getGoals(user));
      }
    }, []); 
    
    return {
        isLoading,
        goals,
        requestError,
        hasLoadedGoals,
    };
}

export default useGoals;
