import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import Trainer from '../../models/Trainer';

interface TrainerState {
    isLoading: boolean;
    hasLoaded: boolean;
    trainers: Trainer[];
    requestError: string;
}

const trainerSlice = createSlice({
  name: 'trainer',
  initialState: {
    isLoading: false,
    hasLoaded: false,
  } as TrainerState,
  reducers: {
    trainersRequested(state) {
      state.isLoading = true;
      state.hasLoaded = false;
    },
    trainerRequestSucceeded(state, action: PayloadAction<Trainer[]>) {
      state.isLoading = false;
      state.hasLoaded = true;
      state.trainers = action.payload;
    },
    trainerRequestFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.hasLoaded = false;
      state.requestError = action.payload;
    }
  }
});

export default trainerSlice;
