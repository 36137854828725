import SignUpField from "./SignUpField.enum";
import HashMap from './HashMap';

interface SignUp {
    displayName: string;
    password: string;
    email: string;
} 

export const toSignUp = (hashmap: HashMap<SignUpField, string>) => {
    return {
        displayName: hashmap.getWithDefault(SignUpField.displayName, ''),
        email: hashmap.getWithDefault(SignUpField.email, ''),
        password: hashmap.getWithDefault(SignUpField.password, ''),
    } as SignUp;
} 

export default SignUp;