import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import Auth from './Auth';
import UserData from './UserData';
import TrainerInfo from './TrainerInfo';
import Message from './Message';
import Goals from './Goals';

const firebaseConfig = {
   apiKey: process.env.REACT_APP_FIREBASE_apiKey,
   authDomain: process.env.REACT_APP_FIREBASE_authDomain,
   databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
   projectId: process.env.REACT_APP_FIREBASE_projectId,
   storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
   messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
   appId: process.env.REACT_APP_FIREBASE_appId,
   measurementId: process.env.REACT_APP_FIREBASE_measurementId
} as const;

firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();

const api = {
   auth: Auth, 
   userData: UserData,
   trainerInfo: TrainerInfo,
   message: Message,
   goals: Goals,
}; 

export default api;