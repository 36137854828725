import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { watchingStateSelector } from '../../store/auth/auth-selectors';
import { subscribeToSignIn } from '../../store/auth/auth-actions';
import LoadingWithOverlay from '../LoadingWithOverlay/LoadingWithOverlay';

interface SignInWatcherProps {
  children: React.ReactNode;
}

const SignInWatcher = ({ children }: SignInWatcherProps) => {
  const {
    isWatching,
    watchingRequested,
  }= useSelector(watchingStateSelector);
  const dispatch = useDispatch();

  // onMount
  useEffect(() => {
    //if (!(isWatching || watchingRequested || isSignedIn)) {
    if (!(isWatching || watchingRequested)) {
      dispatch(subscribeToSignIn());
    }
  }, []);

  if (isWatching) return <>{children}</>;

  return <LoadingWithOverlay contained={false} />;
};

export default SignInWatcher;
