import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './trainers.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCard from '../../components/ListCard/ListCard';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import { Redirect } from 'react-router-dom';
import useTrainers from '../../store/trainers/hooks/useTrainers';
import Trainer from '../../models/Trainer';
import useUserSelected from '../../store/userData/hooks/useUserSelected';
import { updateSelectedTrainer } from '../../store/userData/user-data-actions';
import { useDispatch } from 'react-redux';
import useSession from '../../custom_hooks/useSession';
import { signOut } from '../../store/auth/auth-actions';

// @TODO api
const headerMessage = 'Trainers';

const PLTrainers  = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const {
        hasLoaded,
        isLoading,
        requestError,
        trainers,
    } = useTrainers();
    const {
        isLoading: isLoadingUserSelected,
        hasLoadedUserSelected,
        userSelected,
    } = useUserSelected();
    const { user } = useSession();
    
    const selectTrainer = (trainer: Trainer) => () => {
        setRedirect(true);
        dispatch(updateSelectedTrainer(user, trainer.id));
    };

    const handleSignOut= () => {
        dispatch(signOut());
        return false;
    };
    
    const noTrainers = hasLoaded && (!trainers || trainers.length === 0);
    
    if (!isLoadingUserSelected && userSelected && userSelected.selectedTrainer && redirect) {
        return <Redirect to={'/exercise-programs'} />;
    }

    return (<>
    { isLoading && (<LoadingWithOverlay contained={false} />)}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <h1>{headerMessage}</h1>
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            {noTrainers && (<div className={styles.noTrainerContainer}>
                <p className={classNames("pure-u-1", styles.noTrainer)}>
                    No <strong> Trainers </strong> have added you.
                </p>
                <a className={styles.utilButton} onClick={handleSignOut}>
                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} size="3x" />
                </a>
            </div>)}
            {hasLoaded && !noTrainers && trainers.map((trainer) => (
                <div className={classNames('pure-u-1 pure-u-sm-1-3 pure-u-lg-1-4', styles.cardContainer)} key={trainer.id}>
                    <ListCard
                        title={trainer.displayName}
                        cardKey={trainer.id}
                        description={trainer.displayName}
                        RightIcon={<FontAwesomeIcon icon={['far', 'address-card']} size="3x" />}
                        onClick={selectTrainer(trainer)}
                    />
                </div>
            ))}
        </section>
    </div>
    </>);
};

export default PLTrainers;