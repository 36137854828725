import firebase from 'firebase';
import Preferences, { PreferenceField } from '../models/Preferences';
import UserData from '../models/UserData';
import User from '../models/User';
import CallableFunctionName from './CallableFunctionName';
import ClientEvent, { ClientEventType } from '../models/ClientEvent';
import StatRecord from '../models/StatRecord';
import UserSelected from '../models/UserSelected';
import { getClientColl } from './helpers';

enum DataPaths {
    selected = 'selected',
    preferences = 'preferences',
    events = 'events',
    stats= 'stats',
    public = 'public',
    public_client = 'client',
}


const UserDataAPI = {
    getUserSelected: async () => {
        const getUserData = firebase.functions().httpsCallable(CallableFunctionName.getUserSelected);
        
        const response = await getUserData();
        const userSelected = response.data as UserSelected;
        
        return userSelected;
    },

    getUserData: async (user: User): Promise<UserData> => {
        const getUserData = firebase.functions().httpsCallable(CallableFunctionName.getUserData);
        
        const response = await getUserData();
        const preferences = await UserDataAPI.getPreferences(user);
        const events = await UserDataAPI.getEvents(user);
        
        const userData = response.data as UserData;
        userData.preferences = preferences;
        userData.events = events;
        
        return userData;
    },
    
    getPreferences: async (user: User):Promise<Preferences> => {
        const clientColl = getClientColl(user.email);
        const getPreferences = clientColl.doc(DataPaths.preferences).get();
        const response = await getPreferences;
        
        return response.data() as Preferences;
    },

    getEvents: async (user: User):Promise<ClientEvent[]> => {
        const clientColl = getClientColl(user.email);
        const getEvents = clientColl.doc(DataPaths.events).get();
        const response = await getEvents;
        
        const events: ClientEvent[] = [];
        
        const data = response.data();
        
        if (data && Object.keys(data).length) {
            Object.keys(data).forEach((k) => {
                events.push(...data[k])
            });
        }
        
        return events;
    },

    savePreference: async (user: User, field: PreferenceField, value: string | number | Array<string|number>): Promise<void> => {
        const clientColl = getClientColl(user.email);
        const setPreferences = clientColl.doc(DataPaths.preferences).set({
            [field]: value,
        }, { merge: true });

        await setPreferences;
    },
    
    saveClientStreamEvent: async(user: User, event: ClientEvent) => {
        const clientColl = getClientColl(user.email);
        const addEvent = clientColl.doc(DataPaths.events).set({
            [event.eventCategory]:  firebase.firestore.FieldValue.arrayUnion(event),
        }, { merge: true });

        await addEvent;
    },
    
    
    getStatRecords: async (user: User):Promise<StatRecord[]> => {
        const clientColl = getClientColl(user.email);
        const getStatRecords = clientColl.doc(DataPaths.stats).get();
        const response = await getStatRecords;
        
        const statRecords: StatRecord[] = [];
        
        const data = response.data();
        
        if (data && Object.keys(data).length) {
            Object.keys(data).forEach((k) => {
                statRecords.push(...data[k])
            });
        }
        
        return statRecords;
    },

    saveClientStatRecord: async(user: User, category: string, records: StatRecord[]) => {
        const clientColl = getClientColl(user.email);
        const docDatum = records.map((record) => {
            return {
                [record.statCategory]: firebase.firestore.FieldValue.arrayUnion(record),
            };
        });
        
        const recordSaves = docDatum.map((docData) =>
            clientColl.doc(DataPaths.stats).set(docData, { merge: true })
        );

        await Promise.all(recordSaves);
    },
    setSelectedTrainer: async (user: User, trainerId: string) => {
        const doc = getClientColl(user.email).doc(DataPaths.selected);
        
        await doc.set({
            selectedTrainer: trainerId,
            selectedExerciseProgram: firebase.firestore.FieldValue.delete(),
        }, { merge: true })

        const userSelected = await doc.get();
        
        return userSelected.data() as UserSelected;
    },
    setSelectedExerciseProgram: async (user: User, exerciseProgram: string) => {
        const doc = getClientColl(user.email).doc(DataPaths.selected);
        
        await doc.set({
            selectedExerciseProgram: exerciseProgram,
        }, { merge: true })

        const userSelected = await doc.get();
        
        return userSelected.data() as UserSelected;
    },
};

export default UserDataAPI;
