import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './pre-loader.module.scss';
import RippleLoader from '../../components/RippleLoader/RippleLoader';
import { Redirect } from 'react-router-dom';
import useUserSelected from '../../store/userData/hooks/useUserSelected';

const PreLoader  = () => {
    const [redirect, redirectTo] = useState('');
    const dispatch = useDispatch();
    const {
        hasLoadedUserSelected,
        userSelected,
    } = useUserSelected();

    useEffect(() => {
        if (hasLoadedUserSelected) {
            if (!(userSelected && userSelected.selectedTrainer)) {
                redirectTo('/pl-trainers');
            } else if (!userSelected.selectedExerciseProgram) {
                redirectTo('/pl-exercise-programs');
            } else {
                redirectTo('/');
            }
        }
    }, [hasLoadedUserSelected, userSelected])
    
    if (redirect) {
        return <Redirect to={redirect} />;
    }
    
    return (<>
    <div className={styles.container}>
        <RippleLoader />
    </div>
    </>);
};

export default PreLoader;