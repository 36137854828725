import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import Goals from '../../models/Goals';
import GoalsFormField from '../../models/GoalsFormField.enum';
import HashMap from '../../models/HashMap';

interface GoalState {
    isLoading: boolean;
    hasLoadedGoals: boolean;
    requestError: string;
    subscriptionId?: string;
    updatingError: string;
    goals?: Goals;
    goalsForm?: HashMap<GoalsFormField, string>;
}

const goalsSlice = createSlice({
  name: 'goals',
  initialState: {
    isLoading: false,
    hasSubscribed: false,
    hasLoadedGoals: false,
    requestError: '',
    updatingError: '',
    goalsForm: new HashMap<GoalsFormField, string>(),
  } as GoalState,
  reducers: {
    goalsRequested(state) {
      state.isLoading = true;
    },
    goalsRequestSucceeded(state, action: PayloadAction<Goals>) {
      const goals = action.payload;

      const goalsForm = new HashMap<GoalsFormField, string>();
      goalsForm.set(GoalsFormField.targetWeight, goals?.targetWeight?.toString() || '');

      state.isLoading = false;
      state.hasLoadedGoals = true;
      state.goals = goals;
      state.goalsForm = goalsForm;
    },
    goalsRequestFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.hasLoadedGoals = false;
      state.requestError = action.payload;
    },
    goalsUpdateRequested(state) {
      state.isLoading = true;
    },
    goalsUpdateCompleted(state, action: PayloadAction<Goals>) {
      state.isLoading = false;
      const goals = action.payload;
      state.goals = goals;
    },
    goalsUpdateFailed(state, action:PayloadAction<string>) {
      state.updatingError = action.payload;
      state.isLoading = false;
    },
    updateGoalsForm(state, action: PayloadAction<{field: GoalsFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.goalsForm?.set(field, value);
    },
    
  }
});

export default goalsSlice;
