import { v4 as createUUID } from 'uuid';
import api from '../../api';
import { showSuccess } from '../../alert/alert';
import ClientEvent, { ClientEventType, generateEventCategory } from '../../models/ClientEvent';
import SignUp from '../../models/SignUp';
import { AppThunk } from '../index';
import authSlice from './auth-slice';

export const signIn = (email: string, password: string): AppThunk => async(dispatch) => {
  const {
    startLoading,
    stopLoading,
    signInHasFailed,
  } = authSlice.actions;

  dispatch(startLoading());
  
  try {
    await api.auth.signIn(email, password);
  } catch(e) {
    dispatch(signInHasFailed(e.message));
  }
  dispatch(stopLoading());
};

export const subscribeToSignIn = (): AppThunk => async(dispatch) => {
  const {
    errorWatching,
    signInSucceeded,
    watchingStarted,
    watchingRequested,
  } = authSlice.actions;

  dispatch(watchingRequested());

  try {
    api.auth.subscribeToAuthStateChange((user) => {
      dispatch(watchingStarted());
      if (user) {
        const today = new Date();
        const logInEvent = {
          date: today.toString(),
          description: 'Signed In',
          eventCategory: generateEventCategory(today),
          eventId: createUUID(),
          eventType: ClientEventType.SIGN_IN,
        } as ClientEvent;
        
        api.userData.saveClientStreamEvent(user, logInEvent);

        dispatch(signInSucceeded(user));
      }
    });
  } catch(e) {
    dispatch(errorWatching(e.message));
  }

};

export const signOut = (): AppThunk => async(dispatch) => {
  const {
    signOutSucceeded,
  } = authSlice.actions;

  await api.auth.signOut();

  dispatch(signOutSucceeded());
};

export const signUp = (signUpUser: SignUp): AppThunk => async(dispatch) => {
  const {
    signUpRequested,
    signUpSucceeded,
    signUpFailed,
  } = authSlice.actions;

  dispatch(signUpRequested());
  
  try {
    await api.auth.signUp(signUpUser);
    showSuccess("Sign Up Successful")
    dispatch(signUpSucceeded());
  } catch(e) {
    dispatch(signUpFailed(e.message));
  }
};
