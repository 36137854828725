import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth/auth-slice';
import userDataSlice from './userData/user-data-slice';
import exerciseStateSlice from './exerciseState/exercise-state-slice';
import trainerSlice from './trainers/trainers-slice';
import messageSlice from './messages/messages-slice';
import goalsSlice from './goals/goals-slice';
import weightSlice from './weight/weight-slice';

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  userData: userDataSlice.reducer,
  exerciseState: exerciseStateSlice.reducer,
  trainers: trainerSlice.reducer,
  messages: messageSlice.reducer,
  goals: goalsSlice.reducer,
  weight: weightSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
