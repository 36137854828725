import MediaSource from './MediaSource';

export enum ExerciseUnit {
    lbs = 'lbs',
    seconds = 'seconds',
    minutes = 'minutes',
    none = 'none'
}

interface Exercise {
    exerciseId: string;
    exerciseName: string;
    category: string
    created: Date;
    imageSrc: string;
    description: string;
    mediaSources: MediaSource[];
    splitDay: number;
    reps: number;
    sets: number;
    order: number;
    unit: ExerciseUnit;
}

export default Exercise