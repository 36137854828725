import api from '../../api';
import { showError } from '../../alert/alert';
import { AppThunk } from '../index';
import trainersSlice from './trainers-slice';

export const getTrainers = (): AppThunk => async(dispatch) => {
  const {
      trainersRequested,
      trainerRequestSucceeded,
      trainerRequestFailed,
  } = trainersSlice.actions;

  dispatch(trainersRequested());
  
  try {
    const trainers = await api.trainerInfo.getTrainers();
    dispatch(trainerRequestSucceeded(trainers));
  } catch(e) {
    dispatch(trainerRequestFailed(e.message));
    showError('Error Loading Your Data');
  }
};