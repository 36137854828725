import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './week.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCard from '../../components/ListCard/ListCard';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import { selectedExercisesSelector } from '../../store/userData/user-data-selectors';
import { Redirect } from 'react-router-dom';

// @TODO api
const headerMessage = 'Workout Plan';

const Week  = () => {
    const [clickedDay, setClickedDay] = useState<null | number>(null);
    const exercises = useSelector(selectedExercisesSelector);
    
    const days = exercises.reduce((max, exercise) => {
        return exercise.splitDay > max
            ? exercise.splitDay
            : max;
    } ,0);
    
    const workoutWeek = Array.from(Array(days).keys()).map(i => i + 1);
    
    const getDescription = (day: number) => {
        const exercisesForDay = exercises.filter((exercise) => exercise.splitDay === day);
        
        return exercisesForDay.reduce((desc, exercise) => (`${exercise.exerciseName}, ${desc}`), '').slice(0, -2);
    };
    
    const handleClick = (splitDay: number) => () => {
        setClickedDay(splitDay);
    };
    
    if (clickedDay) {
        return <Redirect to={`/workouts/${clickedDay}`} />;
    }

    return (
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>{headerMessage}</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            {workoutWeek.map((day) => (
                <div className={classNames('pure-u-1 pure-u-sm-1-3 pure-u-lg-1-4', styles.cardContainer)} key={day}>
                    <ListCard
                        title={`Day ${day}`}
                        cardKey={day}
                        description={getDescription(day)}
                        RightIcon={<FontAwesomeIcon icon={['fas', 'caret-right']} size="lg" />}
                        onClick={handleClick(day)}
                    />
                </div>
            ))}
        </section>
    </div>);
};

export default Week;