import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectedSelector } from '../user-data-selectors';
import { getUserSelected } from '../user-data-actions';

const useUserSelected = function useClients() {
    const dispatch = useDispatch();
    const {
        userSelected,
        isLoading,
        hasLoadedUserSelected,
        requestError,
    } = useSelector(userSelectedSelector);
    
    useEffect(() => {
      if (!(hasLoadedUserSelected || isLoading)) {
        dispatch(getUserSelected());
      }
    }, []); 
    
    return {
        userSelected,
        isLoading,
        hasLoadedUserSelected,
        requestError,
    };
}

export default useUserSelected;