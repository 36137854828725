import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './history.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';
import HRule from '../../components/HRule/HRule';
import { format, compareDesc } from 'date-fns'
import { clientEventsSelector } from '../../store/userData/user-data-selectors';



const History  = () => {
    const events = useSelector(clientEventsSelector);
    
    const history = events.map(event => ({
        ...event,
        recordDate: new Date(event.date),
    })).sort((a, b) => compareDesc(a.recordDate, b.recordDate));

    return (
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path="/" />
            <h1>History</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            <dl className={classNames('pure-u-1', styles.datalist)}>
                {history.map((log) => (<React.Fragment key={log.eventId}>
                    <dt>
                        <span className={styles.logIcon}>
                            <FontAwesomeIcon icon={['fas', 'circle']} size="sm" />
                        </span>
                        <span className={styles.date}>{format(log.recordDate, 'dd MMM yyyy')} at {format(log.recordDate, 'h:m a')}</span>
                    </dt>
                    <dd>{log.description}</dd>
                </React.Fragment>))}
            </dl>
        </section>
    </div>);
};

export default History;