import firebase from 'firebase';
import { v4 as createUUID } from 'uuid';
import Message from '../models/Message';
import User from '../models/User';
import { getClientColl } from './helpers';

type MessagesUpdatedHandler = (clients: Message[]) => void;

const SUBSCRIPTION_CACHE: Map<string, () => void> = new Map();

enum DataPaths {
    messages = 'messages',
}

const MessageAPI = {
    subscribeToMessages: async (user: User, messagesUpdated: MessagesUpdatedHandler) => {
        const clientColl = getClientColl(user.email);
        const messagesDoc = clientColl.doc(DataPaths.messages);

        const unsubscribe = messagesDoc.onSnapshot((snapshot) => {
            let storeMessages: Message[] = [];
            
            const docData = snapshot.data();
            
            if (docData) {
                const keys = Object.keys(docData);
                
                keys.forEach((k) => {
                  const data = docData[k];

                  const message: Message = {
                      messageId: k,
                      message: data.message,
                      messageTo: data.messageTo,
                      messageFrom: data.messageFrom,
                      timestamp: data.timestamp,
                      isUserMessage: data.isUserMessage,
                      trainerId: data.trainerId,
                  };

                  storeMessages = [...storeMessages, message];
                });

            }


          messagesUpdated(storeMessages);
        });
        
        const subscriptionUUID = createUUID();

        SUBSCRIPTION_CACHE.set(subscriptionUUID, unsubscribe);

        return subscriptionUUID;
    },

    unsubscribeFromMessages: (subscriptionId: string) => {
        if (SUBSCRIPTION_CACHE.has(subscriptionId)) {
            const unsubscribe = SUBSCRIPTION_CACHE.get(subscriptionId);
            unsubscribe && unsubscribe();
            SUBSCRIPTION_CACHE.delete(subscriptionId);
        }
    },
    
    addMessage: (user: User, trainerDisplayName: string, trainerId: string, message: string) => {
        const clientColl = getClientColl(user.email);
        const messagesDoc = clientColl.doc(DataPaths.messages);
        const messageId = createUUID();

        messagesDoc.set({
            [messageId]: {
                messageId,
                message,
                messageTo: trainerDisplayName,
                messageFrom: user.displayName,
                timestamp: (new Date()).toString(),
                trainerId,
                isUserMessage: true,
            }
        }, { merge: true })
    },

};


export default MessageAPI;
