import { createSelector } from '@reduxjs/toolkit';
import { stat } from 'fs';
import Exercise from '../../models/Exercise';
import ExerciseProgram from '../../models/ExerciseProgram';
import HashMap from '../../models/HashMap';
import { RootState } from '../root-reducer';

export const userDataStateSelector = (state: RootState) => state.userData;

export const isLoadingSelector = createSelector(userDataStateSelector, (state) => state.isLoading);

export const preferencesSelector = createSelector(userDataStateSelector, (state) => state.userData?.preferences);
export const clientEventsSelector = createSelector(userDataStateSelector, (state) => state.userData?.events);

export const exerciseProgramsSelector = createSelector(userDataStateSelector, (state) => ({
    isLoading: state.isLoading,
    exerciseProgram: state.userData.exerciseProgram,
    selectedExerciseProgram: state.userData.preferences?.selectedExerciseProgram,
}));

export const selectedExerciseProgramSelector = createSelector(userDataStateSelector, (state) => {
    return state.userData?.exerciseProgram
});

export const selectedExercisesSelector = createSelector(selectedExerciseProgramSelector, (selectedExerciseProgram) => {
    if (selectedExerciseProgram) {
        return selectedExerciseProgram.exercises;
    }

    return [] as Exercise[];
});

export const numberOfDaysSelector = createSelector(selectedExercisesSelector, (exercises) => {
    const days = exercises.reduce((max, exercise) => {
        return exercise.splitDay > max
            ? exercise.splitDay
            : max;
    } ,0);
    
    return days;
});

export const lastExerciseSelector = createSelector(
    selectedExercisesSelector,
    preferencesSelector,
    (exercises, preferences) => {
        if (!(exercises && exercises.length && preferences?.lastExercise)) {
            return null;
        }
        
        const lastExerciseId = preferences.lastExercise;
        
        return exercises.find((exercise) => exercise.exerciseId === lastExerciseId);
    });

export const nextExerciseSelector = createSelector(selectedExercisesSelector,preferencesSelector, numberOfDaysSelector,
(exercises, preferences, numberOfDays) => {
    if (!(exercises && exercises.length)) {
        return null;
    }
    const lastExercise = preferences?.lastExercise
        ? exercises.find((exercise) => exercise.exerciseId === preferences.lastExercise)
        : null;
        
    if (lastExercise) {
        const nextExercise = exercises.find((exercise) =>
            exercise.splitDay === lastExercise.splitDay
            && exercise.order === lastExercise.order + 1
        )
        
        if (nextExercise) {
            return nextExercise;
        }
    }
    
    if (!lastExercise || (lastExercise.splitDay + 1 > numberOfDays)) {
        const workoutExercises = exercises
            .filter(exercise => exercise.splitDay === 1)
            .sort((a, b) => a.order - b.order);
            
        if (workoutExercises.length) return workoutExercises[0];
    }
    
    const workoutExercises = exercises
        .filter(exercise => exercise.splitDay === (lastExercise?.splitDay || 0) + 1)
        .sort((a, b) => a.order - b.order);
        
    if (workoutExercises.length) return workoutExercises[0];
});

export const lastExercisesForDaySelector = createSelector(
    selectedExercisesSelector,
    (exercises) => {
        if (!(exercises && exercises.length)) {
            return new HashMap<number, string>();
        }
        
        const splitDays = exercises.reduce((days, exercise) => {
            if (days.indexOf(exercise.splitDay) < 0) {
                return [...days, exercise.splitDay];
            }
            
            return days;
        }, [] as number[]);
        
        return splitDays.reduce((lastExerciseForDay, day) => {
            const exercisesForDay = exercises.filter(exercise => exercise.splitDay === day)
                .sort((a, b) => a.order - b.order);
            const lastExercise = exercisesForDay[exercisesForDay.length - 1];
            
            lastExerciseForDay.set(day, lastExercise.exerciseId);
            
            return lastExerciseForDay;
         }, new HashMap<number, string>());
    });

export const statsSelector = createSelector(userDataStateSelector, (state) => ({
    statRecords: state.statRecords,
    isLoading: state.isLoading,
    requestError: state.requestError,
    hasLoadedStatRecords: state.hasLoadedStatRecords,
}));

export const userSelectedSelector = createSelector(userDataStateSelector, (state) => ({
    userSelected: state.userSelected,
    isLoading: state.isLoading,
    requestError: state.requestError,
    hasLoadedUserSelected: state.hasLoadedUserSelected,
}));