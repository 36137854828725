import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './trainers.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListCard from '../../components/ListCard/ListCard';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import { Redirect } from 'react-router-dom';
import useTrainers from '../../store/trainers/hooks/useTrainers';
import Trainer from '../../models/Trainer';
import { updateSelectedTrainer } from '../../store/userData/user-data-actions';
import { useDispatch } from 'react-redux';
import useSession from '../../custom_hooks/useSession';
import BackIconLink from '../../components/BackIconLink/BackIconLink';
import HomeIconLink from '../../components/HomeIconLink/HomeIconLink';

// @TODO api
const headerMessage = 'Trainers';

const Trainers  = () => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const {
        hasLoaded,
        isLoading,
        trainers,
    } = useTrainers();
    const { user } = useSession();
    
    const selectTrainer = (trainer: Trainer) => () => {
        setRedirect(true);
        dispatch(updateSelectedTrainer(user, trainer.id));
    };
    
    const noTrainers = hasLoaded && (!trainers || trainers.length === 0);
    
    if (redirect) {
        return <Redirect to={'/exercise-programs'} />;
    }

    return (<>
    { isLoading && (<LoadingWithOverlay contained={false} />)}
    <div className={styles.container}>
        <section className={styles.topSection}>
            <BackIconLink path='/' />
            <h1>{headerMessage}</h1>
            <HomeIconLink path="/" />
        </section>
        <section className={classNames('pure-g', styles.bottomSection)}>
            {noTrainers && (<p className="pure-u-1">
                No <strong> Trainers </strong> have added you.
            </p>)}
            {hasLoaded && !noTrainers && trainers.map((trainer) => (
                <div className={classNames('pure-u-1 pure-u-sm-1-3 pure-u-lg-1-4', styles.cardContainer)} key={trainer.id}>
                    <ListCard
                        title={trainer.displayName}
                        cardKey={trainer.id}
                        description={trainer.displayName}
                        RightIcon={<FontAwesomeIcon icon={['far', 'address-card']} size="3x" />}
                        onClick={selectTrainer(trainer)}
                    />
                </div>
            ))}
        </section>
    </div>
    </>);
};

export default Trainers;