import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './sign-up.module.scss';
import Wave, { FillColor } from '../../components/Wave/Wave';
import authSlice from '../../store/auth/auth-slice';
import { signUpFormSelector } from '../../store/auth/auth-selectors';
import SignUpField from '../../models/SignUpField.enum'
import FormError from '../../components/FormError/FormError';
import { Link, Redirect } from 'react-router-dom';
import { signUp } from '../../store/auth/auth-actions';
import { toSignUp } from '../../models/SignUp';
import LoadingWithOverlay from '../../components/LoadingWithOverlay/LoadingWithOverlay';
import useSession from '../../custom_hooks/useSession';


const SignUp  = () => {
    const dispatch = useDispatch();
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    const { isSignedIn } = useSession();

    const {
        form,
        isLoading,
    } = useSelector(signUpFormSelector);

    const { updateSignUpForm } = authSlice.actions;

    const requiredFields = [SignUpField.displayName, SignUpField.email, SignUpField.password];

    const handleSignUpClick = () => {
        
        const isValid = requiredFields.reduce((valid, field) => {
            return valid && !!form.get(field);
        }, true);
        
        if (isValid) {
            setShowErrors(false);
            dispatch(signUp(toSignUp(form)));
        } else {
            setShowErrors(true);
        }
    };

    const handleFieldChange = (field: SignUpField) => (ev: React.FormEvent<HTMLInputElement>) => {
        dispatch(updateSignUpForm({
            field,
            value: ev.currentTarget.value,
        }));
    };

    const getError = (field: SignUpField) => {
        if (showErrors && !form.getWithDefault(field, '')) {
            return 'required';
        }
    };

    const getPwConfirmError = () => {
        if (showErrors && !passwordConfirm) {
            return 'required';
        }

        if (passwordConfirm
            && form.getWithDefault(SignUpField.password, '')
            && passwordConfirm !== form.getWithDefault(SignUpField.password, '')
        ) {
            return 'password and confirmation must match';
        }
    };

    if (isSignedIn) return <Redirect to="/preloader" />;

    return (<>
        { isLoading && (
            <LoadingWithOverlay contained={false} />
        )}
        <div className={classNames(styles.container)}>
            <h1>Sign Up</h1>
            <div className={classNames("pure-form", styles.fieldsSection)}>
                <div className="pure-u-1 pure-u-sm-1-2 pure-u-lg-1-3">
                    <div className="pure-control-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" type="email" className="pure-input-1" placeholder="Email"
                            onChange={handleFieldChange(SignUpField.email)}
                            value={form.getWithDefault(SignUpField.email, '')}
                            required
                        />
                        <FormError errorMessage={getError(SignUpField.email)} />
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" type="password" className="pure-input-1" placeholder="Password"
                            onChange={handleFieldChange(SignUpField.password)}
                            value={form.getWithDefault(SignUpField.password, '')}
                            required
                        />
                        <FormError errorMessage={getError(SignUpField.password)} />
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="password">Password Confirm</label>
                        <input id="password" type="password" className="pure-input-1" placeholder="Password"
                            onChange={(ev: React.FormEvent<HTMLInputElement>) => { setPasswordConfirm(ev.currentTarget.value); }}
                            value={passwordConfirm}
                            required
                        />
                        <FormError errorMessage={getPwConfirmError()} />
                    </div>
                    <div className="pure-control-group">
                        <label htmlFor="firstName">Display Name</label>
                        <input id="displayName" type="text" className="pure-input-1" placeholder="Display Name"
                            onChange={handleFieldChange(SignUpField.displayName)}
                            value={form.getWithDefault(SignUpField.displayName, '')}
                            required
                        />
                        <FormError errorMessage={getError(SignUpField.displayName)} />
                    </div>
                </div>
                <div className="pure-u-1 pure-u-sm-1-2 pure-u-lg-1-3">
                    <div className={classNames("pure-control-group", styles.buttonContainer)}>
                        <button type="submit" className={
                            classNames("pure-button pure-input-1 pure-button-primary", styles.signUpButton)}
                            onClick={handleSignUpClick}
                        >
                            Sign Up
                        </button>
                        <p className={styles.signInContainer}>
                            Already have an account?
                            <Link to="/sign-in" className={styles.signInLink}>
                                Sign In
                            </Link>
                        </p>

                    </div>
                </div>
            </div>
            <div className={styles.waveContainer2}>
                <Wave fillColor={FillColor.primary} />
            </div>
            <div className={styles.waveContainer1}>
                <Wave fillColor={FillColor.secondary} />
            </div>
        </div>
    </>);
};

export default SignUp;
