import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagesStateSelector } from '../messages-selectors';
import messagesSlice from '../messages-slice';
import { subscribeToMessages, unsubscribeFromMessages } from '../messages-actions';
import useSession from '../../../custom_hooks/useSession';

const useMessages = function useClients() {
    const dispatch = useDispatch();
    const {
        isLoading,
        hasSubscribed,
        messages,
        subscriptionError,
        subscriptionId,
    } = useSelector(messagesStateSelector);
    const { user } = useSession();
    
    useEffect(() => {
      if (!(hasSubscribed || isLoading)) {
        dispatch(subscribeToMessages(user));
      } else if (subscriptionId) {
        return () => { unsubscribeFromMessages(subscriptionId); };
      }
    }, []); 
    
    return {
        isLoading,
        hasSubscribed,
        messages,
        subscriptionError,
    };
}

export default useMessages;