import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDataStateSelector, userSelectedSelector } from '../user-data-selectors';
import { getUserData, getUserSelected } from '../user-data-actions';
import useSession from '../../../custom_hooks/useSession';

const useUserData = function useClients() {
    const dispatch = useDispatch();
    const { user } = useSession();
    
    const { 
        hasLoadedUserData,
        isLoading: isLoadingUserData,
        userData,
    } =useSelector(userDataStateSelector);
    
    useEffect(() => {
        if (!(isLoadingUserData || hasLoadedUserData)) {
            dispatch(getUserData(user));
        }
    }, []);
    
    return {
        hasLoadedUserData,
        isLoadingUserData,
        userData,
    };
}

export default useUserData;