import api from '../../api';
import { showError, showSuccess } from '../../alert/alert';
import { AppThunk } from '../index';
import weightSlice from './weight-slice';
import User from '../../models/User';
import StatRecord, { generateStatRecordCategory, StatRecordType } from '../../models/StatRecord';
import { v4 as createUUID } from 'uuid';

const createWeightRecord = (value: string, statCategory: string) => {
  const today = new Date();
  return {
    date: today.toString(),
    statCategory,
    statRecordId: createUUID(),
    value: parseInt(value),
    statRecordType: StatRecordType.WEIGHT,
  } as StatRecord;
}

export const saveWeight = (user: User, weight: string) : AppThunk => async(dispatch) => {
  const {
    weightUpdateRequested,
    weightUpdateCompleted,
    weightUpdateFailed,
  } = weightSlice.actions;
  
  dispatch(weightUpdateRequested());
  
  try {
    const today = new Date();
    const statRecordCategory =  generateStatRecordCategory(today);
    const weightRecord = createWeightRecord(weight, statRecordCategory)

    await api.userData.saveClientStatRecord(user, statRecordCategory, [weightRecord]);

    dispatch(weightUpdateCompleted(weight));
    showSuccess('Weight has been saved!');
  } catch(e) {
    dispatch(weightUpdateFailed(e.message));
    showError('Error Updating Weight');
  }
};
