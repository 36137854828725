import firebase from 'firebase';
import User from '../models/User';
import SignUp from '../models/SignUp';

type AuthStateChangeHandler = (user: User|null) => void;

const AuthAPI = {
    signUp: async (signUpUser: SignUp) => {
        return firebase.auth().createUserWithEmailAndPassword(signUpUser.email, signUpUser.password)
        .then(() => {
            const user = firebase.auth().currentUser;
            
            if (user) {
                return user.updateProfile({
                    displayName: signUpUser.displayName,
                });
            }
        })
    },
    signIn: async (email: string, password: string) => {
        firebase.auth().signInWithEmailAndPassword(email, password);
    },
    subscribeToAuthStateChange: (onAuthStateChangeHandler: AuthStateChangeHandler) => {
        firebase.auth().onAuthStateChanged((payload) => {
            if (!payload) {
                onAuthStateChangeHandler(null);
                return;
            }

            const user = {
                uid: payload.uid,
                email: payload.email,
                displayName: payload.displayName,
            } as User;

            onAuthStateChangeHandler(user);
        });
    },
    signOut: () => {
        return firebase.auth().signOut(); 
    },
};

export default AuthAPI;
