import { createSlice , PayloadAction } from '@reduxjs/toolkit';
import HashMap from '../../models/HashMap';
import User from '../../models/User';
import SignUpFormField from '../../models/SignUpField.enum';
import SignInFormField from '../../models/SignInField.enum';

interface AuthState {
  signInForm: HashMap<SignInFormField, string>;
  isSignedIn: boolean;
  isLoading: boolean;
  watchingRequested: boolean;
  isWatching: boolean;
  userSignInError: string;
  watchError: string;
  user?: User;
  signUpForm: HashMap<SignUpFormField, string>;
  signUpError: string;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isSignedIn: false,
    isLoading: false,
    isWatching: false,
    watchingRequested: false,
    signUpForm: new HashMap<SignUpFormField, string>(),
    signInForm: new HashMap<SignInFormField, string>(),
  } as AuthState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    signInHasFailed(state, action: PayloadAction<string>) {
      state.userSignInError = action.payload;
      state.isSignedIn = false;
      delete state.user;
    },
    signInSucceeded(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isSignedIn = true;
    },
    watchingRequested(state) {
      state.watchingRequested = true;
    },
    watchingStarted(state) {
      state.isWatching = true;
    },
    errorWatching(state, action: PayloadAction<string>) {
      state.watchError = action.payload;
    },
    signOutSucceeded(state) {
      state.isSignedIn = false;
      state.isLoading = false;
      delete state.user;
    },
    signUpRequested(state) {
      state.isLoading = true;
    },
    signUpSucceeded(state) {
      state.isLoading = false;
    },
    signUpFailed(state, action: PayloadAction<string>) {
      state.signUpError = action.payload;
      state.isLoading = false;
    },
    updateSignUpForm(state, action: PayloadAction<{field: SignUpFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.signUpForm.set(field, value);
    },
    clearSignUpForm(state) {
      state.signUpForm.clear();
    },
    updateSignInForm(state, action: PayloadAction<{field: SignInFormField, value: string}>) {
      const { field, value} = action.payload;
      
      state.signInForm.set(field, value);
    },
    clearSignInForm(state) {
      state.signInForm.clear();
    },
  }
});

export default authSlice;
